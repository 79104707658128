import {toast} from "react-toastify";

export default function showToastMessage(text, notificationType = "success") {
    if (notificationType === "success") {
        toast.success(text, null)
    } else if (notificationType === "error") {
        toast.error(text, null)
    } else {
        toast.success(text, null)
    }
}