import React, {Component} from 'react';
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect
} from 'react-router-dom';
import Loadable from 'react-loadable';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-modal-slider/lib/main.css';
import Notifications, {notify} from 'react-notify-toast';
import async from 'async';
import _ from 'lodash';
import LoadingComponent from './components/Loader/BasicLoader';
import Header from './components/Header/Header';
import Footer from './components/Footer';
import NotFound from './components/NotFound';
import Sidebar from './components/Sidebar/Sidebar';
import Login from './components/Login/Login';
import AppVersionModal from './components/AppVersionModal';
import callApi, {callApiAndCache} from './util/apiCaller';
import callIdApi from "./util/idApiCaller";
import AccessControl from './components/PermissionLocks/AccessControl';
import PageAccessControl from "./components/PermissionLocks/PageAccessControl";
import sidebarLinks from './constants/sidebar';
import BreadCrumb from "./components/BreadCrumb";
import utility from "./util/utility";
import PubNubReact from 'pubnub-react';
import moment from 'moment';
import {version} from '../package.json';
import ReSetPassword from './components/ReSetPassword/ReSetPassword';
import Connector from './Connector';
import callEventApi from './util/eventApiCaller';
import {setAgentDetails} from './redux/admin/AppAction';
import { getAgentId, getAgentName } from './redux/admin/AppReducer';
import { connect } from 'react-redux';
import {INSIDE_SALES, SALES, VISIT_SALES} from './constants/enum';
import {ADMIN} from '../src/constants/appEnums';

const DIALER_PUBNUB_PUBLISHER_KEY = 'pub-c-c2d3bd6d-c78c-4ca0-be50-b342ce4744ed';
const DIALER_PUBNUB_SUBSCRIBER_KEY = 'sub-c-bee3adec-2f16-11ea-aaf2-c6d8f98a95a1';

const PROTOCOL = window.location.protocol;
const HOST = window.location.host;
const endpoint = `${process.env.REACT_APP_API_URL}`;

let socket;

const AsyncProfile = Loadable({
    loader: () => import('./modules/Profile/Profile/Profile'),
    loading: LoadingComponent,
});
const AsyncUserSettings = Loadable({
    loader: () => import('./modules/Profile/UserSettings/UserSettings'),
    loading: LoadingComponent,
});
const AsyncChangePassword = Loadable({
    loader: () => import('./modules/Profile/UserChangePassword/NewPassword'),
    loading: LoadingComponent,
});
const AsyncAdminDashboard = Loadable({
    loader: () => import('./modules/Admin/AdminDashboard/AdminDashboard'),
    loading: LoadingComponent,
});
const AsyncAdminMonthlyReporting = Loadable({
    loader: () => import('./modules/Reports/AdminMonthlyReporting/AdminMonthlyReporting'),
    loading: LoadingComponent,
});
const AsyncAssociatedSubareaList = Loadable({
    loader: () => import('./modules/Content/AssociatedSubareaList/AssociatedSubareaList'),
    loading: LoadingComponent,
});
const AsyncAssociatedSubareaUpdator = Loadable({
    loader: () => import('./modules/Content/AssociatedSubareaUpdator/AssociatedSubareaUpdator'),
    loading: LoadingComponent,
})
const AsyncAdminNotifications = Loadable({
    loader: () => import('./modules/Admin/AdminNotifications/AdminNotifications'),
    loading: LoadingComponent,
});
const AsyncAdminReporting = Loadable({
    loader: () => import('./modules/Reports/AdminReporting/AdminReporting'),
    loading: LoadingComponent,
});
const AsyncAdminAccessList = Loadable({
    loader: () => import('./modules/Admin/AdminAccessList/AdminAccessList'),
    loading: LoadingComponent,
});
const AsyncVendorPhotographerList = Loadable({
    loader: () => import('./modules/Admin/VendorPhotographerList/VendorPhotographerList'),
    loading: LoadingComponent,
});
const AsyncVendorMehendiList = Loadable({
    loader: () => import('./modules/Admin/VendorMehendiList/VendorMehendiList'),
    loading: LoadingComponent,
});
const AsyncVendorMakeupList = Loadable({
    loader: () => import('./modules/Admin/VendorMakeupList/VendorMakeupList'),
    loading: LoadingComponent,
});
const AsyncVenueLeadList = Loadable({
    loader: () => import('./modules/Admin/VenueLeadList/VenueLeadList'),
    loading: LoadingComponent,
});
const AsyncAdminVenueList = Loadable({
    loader: () => import('./modules/Venues/AdminVenueList/AdminVenueList'),
    loading: LoadingComponent,
});
const AsyncVenueContentList = Loadable({
    loader: () => import('./modules/Venues/VenueContentList/VenueContentList'),
    loading: LoadingComponent,
});
const AsyncAdLeadsVenueList = Loadable({
    loader: () => import('./modules/Venues/AdLeadsVenueList/AdLeadsVenueList'),
    loading: LoadingComponent,
});
const AsyncVenueExpertList = Loadable({
    loader: () => import('./modules/Venues/VenueExpertList/VenueExpertList'),
    loading: LoadingComponent,
});
const AsyncVenueDriveList = Loadable({
    loader: () => import('./modules/Venues/VenuedriveList/VenuedriveList'),
    loading: LoadingComponent,
});
const NotFoundVenueList = Loadable({
    loader: () => import('./modules/Feedback/NotFoundVenueList/NotFoundVenueList'),
    loading: LoadingComponent,
});
const FutureEventList = Loadable({
    loader: () => import('./modules/Feedback/FutureEventList/FutureEventList'),
    loading: LoadingComponent,
});
const AsyncAdminVenuetypeUpdator = Loadable({
    loader: () => import('./modules/Venues/AdminVenuetypeUpdator/AdminVenuetypeUpdator'),
    loading: LoadingComponent,
});
const AsyncAgentEnquiries = Loadable({
    loader: () => import('./modules/Admin/AgentEnquiries/AgentEnquiries'),
    loading: LoadingComponent,
});
const AsyncAllEnquiries = Loadable({
    loader: () => import('./modules/Admin/AgentEnquiries/AllLeadsEnquiries'),
    loading: LoadingComponent,
});
const AsyncAgentList = Loadable({
    loader: () => import('./modules/Admin/AgentList/AgentList'),
    loading: LoadingComponent,
});
const AsyncAgentOnlineList = Loadable({
    loader: () => import('./modules/Admin/AgentOnlineList/AgentOnlineList'),
    loading: LoadingComponent,
});
const AsyncAgentCallData = Loadable({
    loader: () => import('./modules/Admin/AgentCallData/AgentCallData'),
    loading: LoadingComponent,
});
const AsyncAgentLeadDashboard = Loadable({
    loader: () => import('./modules/Admin/AgentLeadDashboard/AgentLeadDashboard'),
    loading: LoadingComponent,
});
const AsyncMyAgentCallData = Loadable({
    loader: () => import('./modules/Admin/MyAgentCallData/MyAgentCallData'),
    loading: LoadingComponent,
});
const AsyncAlgoGraph = Loadable({
    loader: () => import('./modules/BD/AlgoGraph/AlgoGraph'),
    loading: LoadingComponent,
});
const AsyncMyPartnerApproval = Loadable({
    loader: () => import('./modules/BD/MyPartnerApprovals/PartnerApprovalList'),
    loading: LoadingComponent,
});
const AsyncBdayPackList = Loadable({
    loader: () => import('./modules/Expressbook/BdayPackList/BdayPackList'),
    loading: LoadingComponent,
});
const AsyncBdayPackUpdator = Loadable({
    loader: () => import('./modules/Expressbook/BdayPackUpdator/BdayPackUpdator'),
    loading: LoadingComponent,
});
const AsyncCategoryList = Loadable({
    loader: () => import('./modules/Content/CategoryList/CategoryList'),
    loading: LoadingComponent,
});
const AsyncExtraPermissionsList = Loadable({
    loader: () => import('./modules/Content/ExtraPermissionsList/ExtraPermissionsList'),
    loading: LoadingComponent,
});
const AsyncColorList = Loadable({
    loader: () => import('./modules/Content/ColorList/ColorList'),
    loading: LoadingComponent,
});
const AsyncCuisineList = Loadable({
    loader: () => import('./modules/Content/CuisineList/CuisineList'),
    loading: LoadingComponent,
});
const AsyncDropdownList = Loadable({
    loader: () => import('./modules/Content/DropdownList/DropdownList'),
    loading: LoadingComponent,
});
const AsyncEmailTaskList = Loadable({
    loader: () => import('./modules/Services/EmailTaskList/EmailTaskList'),
    loading: LoadingComponent,
});
const AsyncEmailTemplateList = Loadable({
    loader: () => import('./modules/Services/EmailTemplateList/EmailTemplateList'),
    loading: LoadingComponent,
});
const AsyncSummaryTemplateList = Loadable({
    loader: () => import('./modules/Services/SummaryTemplateList/SummaryTemplateList'),
    loading: LoadingComponent,
});
const AsyncSearchlinkSummaryTemplate = Loadable({
    loader: () => import('./modules/Services/SearchlinkSummaryTemplate/SearchlinkSummaryTemplate'),
    loading: LoadingComponent,
});
const AsyncEmailTemplatePreview = Loadable({
    loader: () => import('./modules/Services/EmailTemplatePreview/EmailTemplatePreview'),
    loading: LoadingComponent,
});
const AsyncEventWonList = Loadable({
    loader: () => import('./modules/Leads/NewEventWonList/NewEventWonList'),
    loading: LoadingComponent,
});
const AsyncEventLostList = Loadable({
    loader: () => import('./modules/Leads/EventLostList/EventLostList'),
    loading: LoadingComponent,
});
const AsyncFacilityList = Loadable({
    loader: () => import('./modules/Content/FacilityList/FacilityList'),
    loading: LoadingComponent,
});
const AsyncFeaturedVenueList = Loadable({
    loader: () => import('./modules/BD/FeaturedVenueList/FeaturedVenueList'),
    loading: LoadingComponent,
});
const AsyncCorporateEventWonList = Loadable({
    loader: () => import('./modules/Corporate/CorporateEventWonList/CorporateEventWonList'),
    loading: LoadingComponent,
});
const AsyncCorporateAllEventList = Loadable({
    loader: () => import('./modules/Corporate/CorporateAllEventList/CorporateAllEventList'),
    loading: LoadingComponent,
});
const AsyncCorporateCouponList = Loadable({
    loader: () => import('./modules/Corporate/CorporateCouponList/CorporateCouponList'),
    loading: LoadingComponent,
});
const AsyncCorporateCouponUpdator = Loadable({
    loader: () => import('./modules/Corporate/CorporateCouponUpdator/CorporateCouponUpdator'),
    loading: LoadingComponent,
});
const AsyncViewCorporateUser = Loadable({
    loader: () => import('./modules/Corporate/CorporateUserDetail/CorporateUserDetail'),
    loading: LoadingComponent,
});
const AsyncOfferList = Loadable({
    loader: () => import('./modules/Corporate/OffersList/OffersList'),
    loading: LoadingComponent,
});
const AsyncOfferUpdator = Loadable({
    loader: () => import('./modules/Corporate/OfferUpdator/OfferUpdator'),
    loading: LoadingComponent,
});
const AsyncReferralCodeList = Loadable({
    loader: () => import('./modules/Corporate/ReferralCodeList/ReferralCodeList'),
    loading: LoadingComponent,
});
const AsyncCorporateUsersList = Loadable({
    loader: () => import('./modules/Corporate/CorporateUsersList/CorporateUsersList'),
    loading: LoadingComponent,
});
const AsyncBizRuleUpdator = Loadable({
    loader: () => import('./modules/BD/BizRuleUpdator/BizRuleUpdator'),
    loading: LoadingComponent,
});
const AsyncFooterBlogList = Loadable({
    loader: () => import('./modules/Content/FooterBlogList/FooterBlogList'),
    loading: LoadingComponent,
});
const AsyncFooterLinkList = Loadable({
    loader: () => import('./modules/Content/FooterLinkList/FooterLinkList'),
    loading: LoadingComponent,
});
const AsyncHomePageContent = Loadable({
    loader: () => import('./modules/Content/HomePageContent/HomePageContent'),
    loading: LoadingComponent,
});
const AsyncHomePageDataList = Loadable({
    loader: () => import('./modules/Content/HomePageDataList/HomePageDataList'),
    loading: LoadingComponent,
});
const AsyncHomePageDataUpdator = Loadable({
    loader: () => import('./modules/Content/HomePageDataUpdator/HomePageDataUpdator'),
    loading: LoadingComponent,
});
const AsyncImageTagsList = Loadable({
    loader: () => import('./modules/Content/ImageTagsList/ImageTagsList'),
    loading: LoadingComponent,
});
const AsyncJobsList = Loadable({
    loader: () => import('./modules/Content/JobsList/JobsList'),
    loading: LoadingComponent,
});
const AsyncKeywordList = Loadable({
    loader: () => import('./modules/Content/KeywordList/KeywordList'),
    loading: LoadingComponent,
});
const AsyncListbizList = Loadable({
    loader: () => import('./modules/Content/ListbizList/ListbizList'),
    loading: LoadingComponent,
});
const AsyncNewsletterList = Loadable({
    loader: () => import('./modules/Content/NewsletterList/NewsletterList'),
    loading: LoadingComponent,
});
const AsyncOccasionList = Loadable({
    loader: () => import('./modules/Content/OccasionList/OccasionList'),
    loading: LoadingComponent,
});
const AsyncEventQualityList = Loadable({
    loader: () => import('./modules/Content/EventQualityList/EventQualityList'),
    loading: LoadingComponent,
});
const AsyncEventPriorityList = Loadable({
    loader: () => import('./modules/Content/EventPriorityList/EventPriorityList'),
    loading: LoadingComponent,
});
const AsyncConstantUpdator = Loadable({
    loader: () => import('./modules/Content/ConstantUpdator/ConstantUpdator'),
    loading: LoadingComponent,
});
const AsyncPackageSuggestionList = Loadable({
    loader: () => import('./modules/Content/PackageSuggestionList/PackageSuggestionList'),
    loading: LoadingComponent,
});
const AsyncPageTagsList = Loadable({
    loader: () => import('./modules/Content/PageTagsList/PageTagsList'),
    loading: LoadingComponent,
});
const AsyncSearchLinkList = Loadable({
    loader: () => import('./modules/Marketing/SearchLinkList/SearchLinkList'),
    loading: LoadingComponent,
});
const AsyncSearchLinkUpdator = Loadable({
    loader: () => import('./modules/Marketing/SearchLinkUpdator/SearchLinkUpdator'),
    loading: LoadingComponent,
});
const AsyncVenueList = Loadable({
    loader: () => import('./modules/Marketing/VenueList/VenueList'),
    loading: LoadingComponent,
});
const AsyncMarketingEmailTaskList = Loadable({
    loader: () => import('./modules/Marketing/MarketingEmailTaskList/MarketingEmailTaskList'),
    loading: LoadingComponent,
});
const AsyncPartyPackList = Loadable({
    loader: () => import('./modules/Expressbook/PartyPackList/PartyPackList'),
    loading: LoadingComponent,
});
const AsyncPackageBuyerList = Loadable({
    loader: () => import('./modules/Expressbook/PackageBuyerList/PackageBuyerList'),
    loading: LoadingComponent,
});
const AsyncPartyPackUpdator = Loadable({
    loader: () => import('./modules/Expressbook/PartyPackUpdator/PartyPackUpdator'),
    loading: LoadingComponent,
});
const AsyncPreVenue = Loadable({
    loader: () => import('./modules/Onboarding/PreVenue/PreVenue'),
    loading: LoadingComponent,
});
const AsyncPreVenueList = Loadable({
    loader: () => import('./modules/Onboarding/PreVenueList/PreVenueList'),
    loading: LoadingComponent,
});
const AsyncRealEventCreator = Loadable({
    loader: () => import('./modules/Realevents/RealEventCreator/RealEventCreator'),
    loading: LoadingComponent,
});
const AsyncRealEventsList = Loadable({
    loader: () => import('./modules/Realevents/RealEventsList/RealEventsList'),
    loading: LoadingComponent,
});
const AsyncRealEventUpdator = Loadable({
    loader: () => import('./modules/Realevents/RealEventUpdator/RealEventUpdator'),
    loading: LoadingComponent,
});
const AsyncRegionList = Loadable({
    loader: () => import('./modules/Content/RegionList/RegionList'),
    loading: LoadingComponent,
});
const AsyncSearchListLoader = Loadable({
    loader: () => import('./modules/SearchListLoader/SearchListLoader'),
    loading: LoadingComponent,
});
const AsyncShortUrlList = Loadable({
    loader: () => import('./modules/Services/ShortUrlList/ShortUrlList'),
    loading: LoadingComponent,
});
const AsyncSmsTaskList = Loadable({
    loader: () => import('./modules/Services/SmsTaskList/SmsTaskList'),
    loading: LoadingComponent,
});
const AsyncWhatsappTaskList = Loadable({
    loader: () => import('./modules/Services/WhatsappTaskList/WhatsappTaskList'),
    loading: LoadingComponent,
});
const AsyncContentList = Loadable({
    loader: () => import('./modules/Services/ContentList/ContentList'),
    loading: LoadingComponent,
});
const AsyncPromoCodeList = Loadable({
    loader: () => import('./modules/Content/PromoCodeList/PromoCodeList'),
    loading: LoadingComponent,
});
const AsyncContentUpdator = Loadable({
    loader: () => import('./modules/Services/ContentUpdator/ContentUpdator'),
    loading: LoadingComponent,
});
const AsyncSmsTemplatesList = Loadable({
    loader: () => import('./modules/Services/SmsTemplatesList/SmsTemplatesList'),
    loading: LoadingComponent,
});
const AsyncPdfTemplatesList = Loadable({
    loader: () => import('./modules/Services/PdfTemplatesList/PdfTemplatesList'),
    loading: LoadingComponent,
});
const AsyncWhatsappTemplatesList = Loadable({
    loader: () => import('./modules/Services/WhatsappTemplatesList/WhatsappTemplatesList'),
    loading: LoadingComponent,
});
const AsyncSubAreaList = Loadable({
    loader: () => import('./modules/Content/SubAreaList/SubAreaList'),
    loading: LoadingComponent,
});
const AsyncPlanList = Loadable({
    loader: () => import('./modules/Subscriptions/PlanList/PlanList'),
    loading: LoadingComponent,
});
const AsyncChequesList = Loadable({
    loader: () => import('./modules/Subscriptions/ChequesList/ChequesList'),
    loading: LoadingComponent,
});
const AsyncPaymentList = Loadable({
    loader: () => import('./modules/Subscriptions/PaymentList/PaymentList'),
    loading: LoadingComponent,
});
const AsyncSubscriptionList = Loadable({
    loader: () => import('./modules/Subscriptions/SubscriptionList/SubscriptionList'),
    loading: LoadingComponent,
});
const AsyncUploader = Loadable({
    loader: () => import('./modules/Services/Uploader/Uploader'),
    loading: LoadingComponent,
});
const AsyncCityList = Loadable({
    loader: () => import('./modules/Services/CityList/CityList'),
    loading: LoadingComponent,
});
const AsyncWorkerJobsList = Loadable({
    loader: () => import('./modules/Scheduler/WorkerJobsList/WorkerJobsList'),
    loading: LoadingComponent,
});
const AsyncFilterList = Loadable({
    loader: () => import('./modules/Services/FilterList/FilterList'),
    loading: LoadingComponent,
});
const AsyncVenueSearchFilterList = Loadable({
    loader: () => import('./modules/VenueFilters/VenueSearchFilterList/VenueSearchFilterList'),
    loading: LoadingComponent,
});
const AsyncFilterUpdator = Loadable({
    loader: () => import('./modules/Services/FilterUpdator/FilterUpdator'),
    loading: LoadingComponent,
});
const AsyncVenueSearchFilterUpdator = Loadable({
    loader: () => import('./modules/VenueFilters/VenueSearchFilterUpdator/VenueSearchFilterUpdator'),
    loading: LoadingComponent,
});
const AsyncVenueBundlesList = Loadable({
    loader: () => import('./modules/Venues/VenueBundlesList/VenueBundlesList'),
    loading: LoadingComponent,
});
const AsyncVenueContactUpdator = Loadable({
    loader: () => import('./modules/Venues/VenueContactUpdator/VenueContactUpdator'),
    loading: LoadingComponent,
});
const AsyncVenueDescriptionUpdator = Loadable({
    loader: () => import('./modules/Venues/VenueDescriptionUpdator/VenueDescriptionUpdator'),
    loading: LoadingComponent,
});
const AsyncVenueSEOUpdator = Loadable({
    loader: () => import('./modules/Venues/VenueSEOUpdator/VenueSEOUpdator'),
    loading: LoadingComponent,
});
const AsyncVenueFinanceUpdator = Loadable({
    loader: () => import('./modules/Venues/VenueFinanceUpdator/VenueFinanceUpdator'),
    loading: LoadingComponent,
});
const AsyncVenueImageUpdator = Loadable({
    loader: () => import('./modules/Venues/VenueImageUpdator/VenueImageUpdator'),
    loading: LoadingComponent,
});
const AsyncVenueMenuUpdator = Loadable({
    loader: () => import('./modules/Venues/VenueMenuUpdator/VenueMenuUpdator'),
    loading: LoadingComponent,
});
const AsyncVenueReviewUpdator = Loadable({
    loader: () => import('./modules/Venues/VenueReviewUpdator/VenueReviewUpdator'),
    loading: LoadingComponent,
});
const AsyncVenueExpertUpdator = Loadable({
    loader: () => import('./modules/Venues/VenueExpertUpdator/VenueExpertUpdator'),
    loading: LoadingComponent,
});
const AsyncVenueReport = Loadable({
    loader: () => import('./modules/Reports/VenueReport/VenueReport'),
    loading: LoadingComponent,
});

const AsyncBundleSetList = Loadable({
    loader: () => import('./modules/CRM/BundleSetList/BundleSetList'),
    loading: LoadingComponent,
});
const AsyncBundleSetUpdator = Loadable({
    loader: () => import('./modules/CRM/BundleSetUpdator/BundleSetUpdator'),
    loading: LoadingComponent,
});
const AsyncFunnelSystemList = Loadable({
    loader: () => import('./modules/CRM/FunnelSystemList/FunnelSystemList'),
    loading: LoadingComponent,
});
const AsyncFunnelUpdator = Loadable({
    loader: () => import('./modules/CRM/FunnelUpdator/FunnelUpdator'),
    loading: LoadingComponent,
});
const AsyncVenueSubscriptionCreator = Loadable({
    loader: () => import('./modules/Subscriptions/VenueSubscriptionCreator/VenueSubscriptionCreator'),
    loading: LoadingComponent,
});
const AsyncVenueTrackerList = Loadable({
    loader: () => import('./modules/Onboarding/VenueTrackerList/VenueTrackerList'),
    loading: LoadingComponent,
});
const AsyncVenueTrackerPage = Loadable({
    loader: () => import('./modules/Onboarding/VenueTrackerPage/VenueTrackerPage'),
    loading: LoadingComponent,
});
const AsyncVenuetypeList = Loadable({
    loader: () => import('./modules/Content/VenuetypeList/VenuetypeList'),
    loading: LoadingComponent,
});
const AsyncVenuetypeUpdator = Loadable({
    loader: () => import('./modules/Content/VenuetypeUpdator/VenuetypeUpdator'),
    loading: LoadingComponent,
});
const AsyncOccasionsUpdator = Loadable({
    loader: () => import('./modules/Content/OccasionUpdator/OccasionUpdator'),
    loading: LoadingComponent,
});
const AsyncVenueUpdator = Loadable({
    loader: () => import('./modules/Venues/VenueUpdator/VenueUpdator'),
    loading: LoadingComponent,
});
const AsyncVendorPhotographerUpdator = Loadable({
    loader: () => import('./modules/Vendors/VendorPhotographerUpdator/VendorPhotographerUpdator'),
    loading: LoadingComponent,
});
const AsyncVendorMehendiArtistUpdator = Loadable({
    loader: () => import('./modules/Vendors/VendorMehendiArtistUpdator/VendorMehendiArtistUpdator'),
    loading: LoadingComponent,
});
const AsyncVendorMakeupArtistUpdator = Loadable({
    loader: () => import('./modules/Vendors/VendorMakeupArtistUpdator/VendorMakeupArtistUpdator'),
    loading: LoadingComponent,
});
const AsyncVenueToVenueDataTransfer = Loadable({
    loader: () => import('./modules/Venues/VenueToVenueDataTransfer/VenueToVenueDataTransfer'),
    loading: LoadingComponent,
});

// const AsyncBlogCategoryList = Loadable({
//     loader: () => import('./modules/Blog/BlogCategoryList/BlogCategoryList'),
//     loading: LoadingComponent,
// });

const AsyncVenueBulkDataUpdator = Loadable({
    loader: () => import('./modules/Venues/VenueBulkDataUpdator/VenueBulkDataUpdator'),
    loading: LoadingComponent,
});
const AsyncVenueFaqList = Loadable({
    loader: () => import('./modules/Venues/VenueFaqList/VenueFaqList'),
    loading: LoadingComponent,
});
const AsyncUserList = Loadable({
    loader: () => import('./modules/Admin/UserList/UserList'),
    loading: LoadingComponent,
});
const AsyncUserSearchList = Loadable({
    loader: () => import('./modules/Admin/UserSearchList/UserList'),
    loading: LoadingComponent,
});
const AsyncRoleList = Loadable({
    loader: () => import('./modules/Admin/RoleList/RoleList'),
    loading: LoadingComponent,
});
const AsyncRoleGroupList = Loadable({
    loader: () => import('./modules/Admin/RoleGroupList/RoleGroupList'),
    loading: LoadingComponent,
});

const AsyncModuleList = Loadable({
    loader: () => import('./modules/Admin/ModuleList/ModuleList'),
    loading: LoadingComponent,
});
const AsyncPartnerAppModuleList = Loadable({
    loader: () => import('./modules/Admin/PartnerAppModuleList/ModuleList'),
    loading: LoadingComponent,
});
const AsyncApprovalAgentList = Loadable({
    loader: () => import('./modules/Admin/ApprovalAgentList/ApprovalAgentList'),
    loading: LoadingComponent,
});
const AsyncApprovalList = Loadable({
    loader: () => import('./modules/Admin/Approvals/ApprovalList'),
    loading: LoadingComponent,
});
const AsyncPartnerApprovalList = Loadable({
    loader: () => import('./modules/Approvals/PartnerApprovals/PartnerApprovalList'),
    loading: LoadingComponent,
});
const AsyncPartnerAccountPermissionUpdator = Loadable({
    loader: () => import('./modules/BD/PartnerPermissionUpdator/PartnerPermissionUpdator'),
    loading: LoadingComponent,
});

const AsyncPermissionList = Loadable({
    loader: () => import('./modules/Admin/PermissionList/PermissionList'),
    loading: LoadingComponent,
});

const AsyncRoleUpdator = Loadable({
    loader: () => import('./modules/Admin/RoleUpdator/RoleUpdator'),
    loading: LoadingComponent,
});

const AsyncFeedbackList = Loadable({
    loader: () => import('./modules/Leads/FeedbackList/FeedbackList'),
    loading: LoadingComponent,
});

const AsyncMyFeedbackList = Loadable({
    loader: () => import('./modules/MyFeedback/MyFeedbackList/MyFeedbackList'),
    loading: LoadingComponent,
});

const AsyncVenueLeadTargetList = Loadable({
    loader: () => import('./modules/Leads/VenueLeadTarget/VenueLeadTargetList'),
    loading: LoadingComponent,
});
const AsyncDataDumper = Loadable({
    loader: () => import('./modules/Analytics/DataDumper/DataDumper'),
    loading: LoadingComponent,
});
const AsyncUserUpdate = Loadable({
    loader: () => import('./modules/Admin/UserUpdate/UserUpdate'),
    loading: LoadingComponent,
});

const AsyncCollectionList = Loadable({
    loader: () => import('./modules/Collections/CollectionsList/CollectionsList'),
    loading: LoadingComponent,
});
const AsyncCollectionUpdator = Loadable({
    loader: () => import('./modules/Collections/CollectionUpdator/CollectionUpdator'),
    loading: LoadingComponent,
});

const AsyncPhotographerList = Loadable({
    loader: () => import('./modules/Photographers/PhotographerList/PhotographerList'),
    loading: LoadingComponent,
});
const AsyncPhotographerUpdator = Loadable({
    loader: () => import('./modules/Photographers/PhotographerUpdator/PhotographerUpdator'),
    loading: LoadingComponent,
});
const AsyncPhotographerContactUpdator = Loadable({
    loader: () => import('./modules/Photographers/PhotographerContactUpdator/PhotographerContactUpdator'),
    loading: LoadingComponent,
});

const AsyncPhotographerFinanceUpdator = Loadable({
    loader: () => import('./modules/Photographers/PhotographerFinanceUpdator/PhotographerFinanceUpdator'),
    loading: LoadingComponent,
});
const AsyncPhotographerImageUpdator = Loadable({
    loader: () => import('./modules/Photographers/PhotographerImageUpdator/PhotographerImageUpdator'),
    loading: LoadingComponent,
});
const AsyncPrephotographerList = Loadable({
    loader: () => import('./modules/Photographers/PrephotographerList/PrephotographerList'),
    loading: LoadingComponent,
});
const AsyncPrephotographer = Loadable({
    loader: () => import('./modules/Photographers/PrephotographerUpdator/PrephotographerUpdator'),
    loading: LoadingComponent,
});
const AsyncExpurlList = Loadable({
    loader: () => import('./modules/Expressbook/ExpurlList/ExpurlList'),
    loading: LoadingComponent,
});
const AsyncTasktypeList = Loadable({
    loader: () => import('./modules/Services/TasktypeList/TasktypeList'),
    loading: LoadingComponent,
});
const AsyncEventTemplateList = Loadable({
    loader: () => import('./modules/Services/EventTemplateList/EventTemplateList'),
    loading: LoadingComponent,
});
const AsyncVenueSummaryTemplate = Loadable({
    loader: () => import('./modules/Services/VenueSummaryTemplate/VenueSummaryTemplate'),
    loading: LoadingComponent,
});
const AsyncVenueFaqTemplate = Loadable({
    loader: () => import('./modules/Services/VenueFaqList/VenueFaqTemplate'),
    loading: LoadingComponent,
});
const AsyncVendorTrackerList = Loadable({
    loader: () => import('./modules/Photographers/PhotographerTrackerList/PhotographerTrackerList'),
    loading: LoadingComponent,
});
const AsyncVendorTrackerPage = Loadable({
    loader: () => import('./modules/Photographers/PhotographerTrackerPage/PhotographerTrackerPage'),
    loading: LoadingComponent,
});
const AsyncSmsEventList = Loadable({
    loader: () => import('./modules/Marketing/SmsToolkit/EventList/Events'),
    loading: LoadingComponent,
});
const AsyncSmsVenueList = Loadable({
    loader: () => import('./modules/Marketing/SmsToolkit/VenueList/Venues'),
    loading: LoadingComponent,
});
const AsyncSmsUserList = Loadable({
    loader: () => import('./modules/Marketing/SmsToolkit/UserList/UserList'),
    loading: LoadingComponent,
});
const AsyncAdLinkList = Loadable({
    loader: () => import('./modules/Marketing/AdLinkList/AdLinkList'),
    loading: LoadingComponent,
});
const AsyncLeadFormList = Loadable({
    loader: () => import('./modules/Services/LeadFormList/LeadFormList'),
    loading: LoadingComponent,
});
const AsyncAdminFeaturedVenueList = Loadable({
    loader: () => import('./modules/Services/AdminFeaturedVenueList/FeaturedVenueList'),
    loading: LoadingComponent,
});
const AsyncAlertSystemList = Loadable({
    loader: () => import('./modules/Services/AlertSystemList/AlertSystemList'),
    loading: LoadingComponent,
});
const AsyncLeadFormUpdator = Loadable({
    loader: () => import('./modules/Services/LeadFormUpdator/LeadFormUpdator'),
    loading: LoadingComponent,
});
const AsyncOnetimeEventList = Loadable({
    loader: () => import('./modules/Scheduler/OnetimeEventList/OnetimeEventList'),
    loading: LoadingComponent,
});

const AsyncRecurringEventList = Loadable({
    loader: () => import('./modules/Scheduler/RecurringEventList/RecurringEventList'),
    loading: LoadingComponent,
});

const AsyncRedirectList = Loadable({
    loader: () => import('./modules/Services/RedirectList/RedirectList'),
    loading: LoadingComponent,
});
const AsyncAgentUpdator = Loadable({
    loader: () => import('./modules/Admin/AgentUpdator/AgentUpdator'),
    loading: LoadingComponent,
});
const AsyncCorporateLeadList = Loadable({
    loader: () => import('./modules/Corporate/CorporateLeadList/CorporateLeadList'),
    loading: LoadingComponent,
});
const AsyncCorporateRuleUpdator = Loadable({
    loader: () => import('./modules/Corporate/CorporateRuleUpdator/CorporateRuleUpdator'),
    loading: LoadingComponent,
});
const AsyncOpenRedemptionList = Loadable({
    loader: () => import('./modules/Corporate/OpenRedemptionList/OpenRedemptionList'),
    loading: LoadingComponent,
});
const AsyncRedemptionList = Loadable({
    loader: () => import('./modules/Corporate/RedemptionList/RedemptionList'),
    loading: LoadingComponent,
});

const AsyncPageDataList = Loadable({
    loader: () => import('./modules/Base/PageDataList/PageDataList'),
    loading: LoadingComponent,
});
const AsyncPageDataUpdator = Loadable({
    loader: () => import('./modules/Base/PageDataUpdator/PageDataUpdator'),
    loading: LoadingComponent,
});
const AsyncBizLeadList = Loadable({
    loader: () => import('./modules/BD/BizLeadList/BizLeadList'),
    loading: LoadingComponent,
});
const AsyncBDLeadAnalytics = Loadable({
    loader: () => import('./modules/BD/BDLeadAnalytics/BDLeadAnalytics'),
    loading: LoadingComponent,
});
const AsyncPackageCostData = Loadable({
    loader: () => import('./modules/BD/PackageCostData/PackageCostData'),
    loading: LoadingComponent,
});
const AsyncPartnerUsersList = Loadable({
    loader: () => import('./modules/BD/PartnerUsersList/PartnerUsersList'),
    loading: LoadingComponent,
});
const AsyncWebLinkList = Loadable({
    loader: () => import('./modules/Caching/WebLinkList/WebLinkList'),
    loading: LoadingComponent,
});
const AsyncApiLinkList = Loadable({
    loader: () => import('./modules/Caching/APILinkList/ApiLinkList'),
    loading: LoadingComponent,
});
const AsyncPartnerUserDetail = Loadable({
    loader: () => import('./modules/BD/PartnerUserDetail/PartnerUserDetail'),
    loading: LoadingComponent,
});
const AsyncEmailTemplatesListForParntnerApp = Loadable({
    loader: () => import('./modules/PartnerAppServices/EmailTemplateList/EmailTemplateList'),
    loading: LoadingComponent,
});
const AsyncSmsTemplatesListForParntnerApp = Loadable({
    loader: () => import('./modules/PartnerAppServices/SmsTemplatesList/SmsTemplatesList'),
    loading: LoadingComponent,
});
const AsyncAgentBdDashboard = Loadable({
    loader: () => import('./modules/Admin/AgentBdDashboard/AgentBdDashboard'),
    loading: LoadingComponent,
});
const AsyncAgentCorpDashboard = Loadable({
    loader: () => import('./modules/Admin/AgentCorpDashboard/AgentCorpDashboard'),
    loading: LoadingComponent,
});
const AsyncAgentFeedbackDashboard = Loadable({
    loader: () => import('./modules/Admin/AgentFeedbackDashboard/AgentFeedbackDashboard'),
    loading: LoadingComponent,
});
const AsyncSalesAgentQueueSystem = Loadable({
    loader: () => import('./modules/Admin/SalesAgentQueueSystem/SalesAgentQueueSystem'),
    loading: LoadingComponent,
});

const AsyncSalesAgentQueueSystemV2 = Loadable({
    loader: () => import('./modules/Admin/SalesAgentQueueSystem/SalesAgentQueueSystemV2'),
    loading: LoadingComponent,
});
const AsyncVenuePaymentList = Loadable({
    loader: () => import('./modules/Finance/VenuePaymentList/VenuePaymentList'),
    loading: LoadingComponent,
});

const AsyncFilterModuleList = Loadable({
    loader: () => import('./modules/VenueFilters/FilterModuleList/FilterModuleList'),
    loading: LoadingComponent,
});
const AsyncPurplePatchLeadList = Loadable({
    loader: () => import('./modules/PurplePatch/PurplePatchLeadList/PurplePatchLeadList'),
    loading: LoadingComponent,
});
const AsyncBlogCategoryList = Loadable({
    loader: () => import('./modules/Blog/BlogCategoryList/BlogCategoryList'),
    loading: LoadingComponent,
});
const AsyncPinnedPostList = Loadable({
    loader: () => import('./modules/Blog/PinnedPostList/PinnedPostList'),
    loading: LoadingComponent,
});
const AsyncFeaturedBlogList = Loadable({
    loader: () => import('./modules/Blog/FeaturedBlogList/FeaturedBlogList'),
    loading: LoadingComponent,
});


const PrivateRoute = ({
                          component: Component,
                          isAuthenticated: isAuthenticated,
                          userPermissions,
                          shouldHavePermissions,
                          ...rest
                      }) => (
    <Route {...rest} render={(props) => (
        isAuthenticated
            ? <PageAccessControl
                userPermissions={userPermissions}
                shouldHavePermissions={shouldHavePermissions}
            >
                <Component {...props} />
            </PageAccessControl>
            : <Redirect to={{
                pathname: '/login',
                state: {_refurl: props.location}
            }}/>
    )}/>
);

class App extends Component {
    constructor() {
        super();
        this.baseBreadCrumbs = [{
            link: '/profile',
            name: 'Home',
            isActive: false
        }];
        this.state = {
            isMounted: false,
            isLoading: false,
            userid: '',
            agentid: '',
            user: {},
            agent: {},
            isAuthenticated: false,
            token: '',
            notification: {
                message: '',
                link: '',
                timeout: 3,
                msgId: '',
            },
            cities: [],
            userPermissions: [],
            isLoggingIn: false,
            links: [],
            contents: [],
            faveLinks: [],
            userprefId: '',
            breadcrumbs: [{
                link: '/profile',
                name: 'Home',
                isActive: false
            }],
        };
        this.isAuthenticated = false;
        this.dialerPubnub = new PubNubReact({
            publishKey: DIALER_PUBNUB_PUBLISHER_KEY,
            subscribeKey: DIALER_PUBNUB_SUBSCRIBER_KEY
        });
        this.dialerPubnub.init(this);
    }

    componentDidMount() {
        console.log('App is running on version :::: ', version);
        console.log("first layer");
        let token = window.localStorage.getItem('token');
        if (token && token !== '') {
            this.setState({
                isLoading: true
            });
            // socket = window.io(endpoint);
            this.verifyToken(token, (err, user) => {
                if (err) {
                    window.localStorage.removeItem('token');
                } else {
                    this.isAuthenticated = true;
                    let model = {
                        user: user
                    };
                    if (!_.isEmpty(model.user)) {
                        this.props.dispatch(setAgentDetails(model.user.name,model.user._id))
                        this.setState({
                            isMounted: true,
                            isLoading: false,
                            isAuthenticated: true,
                            userid: model.user._id,
                            agentid: model.user._id,
                            token: token,
                            user: model.user,
                            agent: model.user,
                        }, () => {
                            async.series([
                                (cb) => {
                                    if (model.user && model.user._id) {
                                        this.fetchUserprefs(model.user._id, (err, userpref) => {
                                            if (!err && userpref) {
                                                model.userprefId = userpref._id;
                                                model.faveLinks = userpref.faveLinks;
                                                return cb();
                                            } else {
                                                return cb();
                                            }
                                        });
                                    } else {
                                        return cb();
                                    }
                                },
                                (cb) => {
                                    if (model.user && model.user.venue_list && model.user.venue_list !== '') {
                                        this.fetchAccessList(model.user.venue_list, (err, access) => {
                                            if (!err && access) {
                                                model.access = access;
                                                return cb();
                                            } else {
                                                return cb();
                                            }
                                        });
                                    } else {
                                        return cb();
                                    }
                                },
                                (cb) => {
                                    this.fetchCityList((err, cities) => {
                                        if (!err && cities && cities.length > 0) {
                                            model.cities = cities.map(item => {
                                                return {
                                                    name: _.startCase(item.name),
                                                    sname: item.name
                                                }
                                            });
                                            return cb();
                                        } else {
                                            return cb();
                                        }
                                    });
                                },
                                (cb) => {
                                    this.fetchContentList((err, contents) => {
                                        if (!err && contents && contents.length > 0) {
                                            model.contents = contents;
                                            return cb();
                                        } else {
                                            return cb();
                                        }
                                    })
                                }
                            ], (err) => {
                                if (err) {
                                    this.setState({
                                        isLoading: false
                                    });
                                } else {
                                    let userPermissions = [];
                                    if (model.user.userPermissions && _.isArray(model.user.userPermissions)) {
                                        userPermissions = userPermissions.concat(model.user.userPermissions);
                                    }
                                    if (model.user.extraPermissions && _.isArray(model.user.extraPermissions)) {
                                        userPermissions = userPermissions.concat(model.user.extraPermissions);
                                    }
                                    this.setState({
                                        isLoading: false,
                                        user: model.user ? model.user : {},
                                        userPermissions: userPermissions,
                                        access: model.access ? model.access : {},
                                        cities: model.cities,
                                        contents: model.contents,
                                        userprefId: model.userprefId ? model.userprefId : '',
                                        faveLinks: model.faveLinks ? model.faveLinks : [],
                                        links: sidebarLinks
                                    });
                                    if (this.state.agentid) {
                                        this.dialerPubnub.subscribe({
                                            channels: [`dialer-${this.state.agentid}`],
                                            withPresence: false
                                        });
                                    }
                                }
                            });
                        });
                    }
                }
            });
            // socket.on('MarkedOnline', function(data){
            //     console.log('socket:' + socket.id);
            // })
        }
    }

    fetchContentList = (next) => {
        callApi(`v2/contents/list`, 'post', {}).then(res => {
            return next(null, res.data.contents);
        })
    }

    // setAgentOnline = (agentId, socketId) => {
    //     socket.emit('agentOnline', { agentId: agentId , type: 'vm-manager', socketId: socketId });
    // };
    //
    // setAgentOffline = (agentId, socketId) => {
    //     socket.emit('agentOffline', { agentId: agentId , type: 'vm-manager', socketId: socketId });
    // };

    connectCall = (phone) => {
        if (phone && utility.isValidIndianMobile(phone)) {
            callEventApi(`dialer/send-message`, 'post', {
                "message": {
                    "event": "connectToCall",
                    "agent_id": this.state.agentid,
                    "phone_number": phone.toString(),
                    "expiry_time": new Date(moment().add(10, 'seconds').toISOString()).getTime()
                }
            }).then(res => {
                if (res.status === "Success") {
                    this.showMessage("Call Processed", "success");
                } else {
                    this.showMessage('Call Failed', 'danger');
                }
            }).catch((e) => {
                console.log(e);
            });
        } else {
            this.showMessage('Please enter valid Indian Number', 'danger');
        }
    };

    // connectCall = (phone) => {
    //       if (phone && utility.isValidIndianMobile(phone)) {
    //TODO v1 to v3 change ritwik

    //           callApi(`v3/admin-dashboard/agent/${this.state.agentid}/push-to-call`, 'post', {
    //               phone: phone
    //           }).then(res => {
    //               if (res.status === 'Success') {
    //                   this.showMessage('Processed', 'success');
    //               } else {
    //                   this.showMessage('Failed', 'danger');
    //               }
    //           });
    //       } else {
    //           this.showMessage('Please enter valid Indian Number', 'danger');
    //       }
    //   };

    // connectCall = (phone) => {
    //     if (phone && utility.isValidIndianMobile(phone)) {
    //         let data = {
    //             agentId :this.state.agentid,
    //             phone: phone
    //         };
    //         socket.emit('connectCall', data);
    //         this.showMessage('Processed', 'success');
    //     } else {
    //         this.showMessage('Please enter valid Indian Number', 'danger');
    //     }
    // };


    updateUser = (user) => {
        this.setState({user: user})
    }

    fetchUser = (userid, next) => {
        callApi(`v2/users/${userid}`, 'GET').then(res => {
            return next(null, res.data.user);
        });
    };

    fetchUserprefs = (userid, next) => {
        callApi(`v3/users/${userid}/faves`, 'GET').then(res => {
            return next(null, res && res.data ? res.data.userpref : null);
        });
    };

    updateUserpref = (userpref) => {
        callApi(`v2/userpref/${this.state.userprefId}/update`, 'POST', {
            userpref: userpref
        }).then(res => {
            if (res.status === 'Success') {
                this.setState({
                    faveLinks: res.data.userpref.faveLinks
                });
                this.showMessage('Links Updated', 'success');
            }
        });
    };

    updateAgent = (agent) => {
        callIdApi(`v2/agents/${this.state.agentid}/update`, 'POST', {
            agent: agent
        }).then(res => {
            if (res.status === 'Success') {
                this.setState({
                    agent: res.data.agent
                });
                this.showMessage('Updated', 'success');
            }
        });
    };

    fetchCityList = (next) => {
        callApiAndCache(`v3/citys/city-list`, 'GET').then(res => {
            return next(null, res.data.cities);
        });
    };

    fetchAgent = (agentid, next) => {
        callIdApi(`v2/agents/${agentid}`, 'GET').then(res => {
            return next(null, res.data.agent);
        });
    };

    fetchAgentUnReadMessageCount = (userid, next) => {
        callApi(`v3/users/${userid}/unread-notifications`, 'GET').then(res => {
            return next(null, res.data.count);
        });
    };

    loginUser = (creds) => {
        this.setState({
            isLoggingIn: true
        });
        // callApi(`auth/login`, 'post', creds).then(res => { /* MANAGER AUTH CALL API */
        callIdApi(`auth/login?app=${ADMIN}`, 'post', creds).then(res => { /* ID AUTH CALL API */
            this.setState({
                isLoggingIn: false
            });
            if (res.user && res.user.ok) {
                window.localStorage.setItem('token', res.token);
                window.localStorage.setItem('user', JSON.stringify(res.user.data));
                window.location = "/";
            } else {
                this.showMessage(res.message, 'error');
            }
        });
    };

    resetPassword = (creds) => {
        // API call
        callIdApi(`auth/forgot-password`, 'post', creds).then(res => { /* ID AUTH CALL API */
            this.setState({
                isLoggingIn: false
            });
            if (res.status === 'Success') {
                this.showMessage(res.data.message, 'success');
            } else {
                this.showMessage(res.message, 'error');
            }
        });
    }
    verifyToken = (token, next) => {
        callIdApi(`auth/verify?app=${ADMIN}`, 'post', {
            token: token
        }).then(res => {
            if (res.user && res.user.ok) {
                let user = res.user.data;
                const user_ = window.localStorage.getItem('user');
                if(!user_) {
                    window.localStorage.setItem('user', JSON.stringify(res.user.data));
                }
                return next(null, user);
            } else {
                return next('Unauthorized');
            }
        });
    };

    logoutUser = () => {
        callEventApi(`v2/agents/${this.state.agentid}/logout-agent`, 'GET').then(res => {
            if (res.status === 'Success') {
                this.showMessage('Logout', 'success');
                window.localStorage.removeItem('token');
                window.localStorage.removeItem('user');
                this.isAuthenticated = false;
                window.location = '/';
            }
        });

    };

    showMessage = (msg, msgtype) => {
        notify.show(msg, msgtype, 3000);
    };

    notifyToAgent = (msg) => {
        let text = msg.text;
        let options = {};
        if (msg.isTimedNotification) {
            options.autoClose = msg.timer;
        } else {
            options.autoClose = false;
        }
        if (msg.notification_type === 'success') {
            toast.success(text, options);
        } else {
            toast.info(text, options);
        }
    };

    clearAgentNotifications = () => {
        toast.dismiss();
    };

    fetchAccessList = (id, next) => {
        callApi(`v2/venueaccesses/${id}/populated`, 'get').then(res => {
            if (res.status === 'Success') {
                let access = res.data.venueaccess;
                return next(null, access);
            } else {
                return next('Unauthorized');
            }
        });
    };

    notifyToAdmin = (message, link, timeout) => {
        let notification = this.state.notification;
        notification.message = message;
        notification.link = link ? link : '';
        notification.timeout = timeout;
        notification.msgId = this.generateMessageId(8);
        this.setState({
            notification: notification,
        });
    };

    rand = (min, max) => {
        let random = Math.random();
        return Math.floor(random * (max - min) + min);
    };

    generateMessageId = (length) => {
        let loopLength = length || 8;
        let allowsChars = '0123456789';
        let password = '';
        for (let index = 0; index < loopLength; ++index) {
            let charIndex = this.rand(0, allowsChars.length - 1);
            password += allowsChars[charIndex];
        }
        return password;
    };

    setBreadCrumbs = (breadcrumbs) => {
        this.setState({
            breadcrumbs: breadcrumbs
        })
    };

    resetBreadCrumbs = () => {
        this.setState({
            breadcrumbs: this.baseBreadCrumbs
        });
    };

    // sendPubNubMessage = ( message) => {
    //     this.pubnub.publish(
    //         {
    //             channel: channels[0],
    //             message,
    //         },
    //         () => {
    //
    //         }
    //     );
    // };

    render() {
        const cities = this.state.cities;
        return (
            <div className="App">
                <Router>
                    <div id="page-content-wrapper">
                        {this.state.isAuthenticated ?
                            <header id="topnav"
                                    className="navbar navbar-default navbar-fixed-top"
                                    role="banner">
                                        <Connector
                                          agentid={this.state.agentid}
                                          showMessage={this.showMessage}
                                          userPermissions={this.state.userPermissions}
                                          cities={cities ? cities : []}
                                          connectCall={this.connectCall}
                                    />
                                <Header
                                    notification={this.state.notification}
                                    fetchAgentUnReadMessageCount={this.fetchAgentUnReadMessageCount}
                                    user={this.state.user}
                                    showMessage={this.showMessage}
                                    notifyToAdmin={this.notifyToAgent}
                                    clearAgentNotifications={this.clearAgentNotifications}
                                    logOut={this.logoutUser}
                                />
                            </header>
                            :
                            null
                        }
                        <div id="wrapper">
                            <div id="layout-static">
                                {this.state.isAuthenticated ?
                                    <Sidebar
                                        faveLinks={this.state.faveLinks}
                                        links={this.state.links}
                                        userPermissions={this.state.userPermissions}
                                    />
                                    :
                                    null
                                }
                                <div className="static-content-wrapper">
                                    <div className="static-content">
                                        <div className="page-content">
                                            <BreadCrumb
                                                // breadcrumbs={this.state.breadcrumbs}
                                            />
                                            <div className="container-fluid"
                                                 style={{minHeight: '550px'}}>
                                                <Switch>
                                                    <Route exact path="/login"
                                                           render={props =>
                                                               <Login {...props}
                                                                      isLoggingIn={this.state.isLoggingIn}
                                                                      isAuthenticated={this.state.isAuthenticated}
                                                                      user={this.state.user}
                                                                      loginUser={this.loginUser}/>}
                                                    />
                                                    <Route exact path="/reset"
                                                           render={props =>
                                                               <ReSetPassword {...props}
                                                                              isLoggingIn={this.state.isLoggingIn}
                                                                              isAuthenticated={this.state.isAuthenticated}
                                                                              user={this.state.user}
                                                                              resetPassword={this.resetPassword}/>}
                                                    />

                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={null}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact path="/"
                                                        component={props =>
                                                            <AsyncProfile
                                                                {...props}
                                                                userPermissions={this.state.userPermissions}
                                                                showMessage={this.showMessage}
                                                                updateUserpref={this.updateUserpref}
                                                                links={this.state.links}
                                                                agent={!_.isEmpty(this.state.agent) ? this.state.agent : {}}
                                                                user={this.state.user}
                                                                updateUser={this.updateUser}
                                                            />}/>
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={null}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact path="/settings"
                                                        component={props =>
                                                            <AsyncUserSettings
                                                                {...props}
                                                                userPermissions={this.state.userPermissions}
                                                                updateUserpref={this.updateUserpref}
                                                                updateAgent={this.updateAgent}
                                                                links={this.state.links}
                                                                user={this.state.user}
                                                                agent={this.state.agent}
                                                                showMessage={this.showMessage}
                                                            />
                                                        }
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={null}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact path="/Change-password"
                                                        component={props =>
                                                            <AsyncChangePassword
                                                                {...props}
                                                                userPermissions={this.state.userPermissions}
                                                                updateUserpref={this.updateUserpref}
                                                                updateAgent={this.updateAgent}
                                                                links={this.state.links}
                                                                user={this.state.user}
                                                                agent={this.state.agent}
                                                                showMessage={this.showMessage}
                                                            />
                                                        }
                                                    />
                                                    <Redirect
                                                        path={'/profile'}
                                                        to={"/"}/>
                                                    <Redirect
                                                        path={'/manager/profile'}
                                                        to={"/"}/>
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/enquiry/closed-won"
                                                        component={props =>
                                                            <AsyncEventWonList {...props}
                                                                               userPermissions={this.state.userPermissions}
                                                                               cities={cities ? cities : []}
                                                                               showMessage={this.showMessage}
                                                                               userid={this.state.userid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/enquiry/closed-lost"
                                                        component={props =>
                                                            <AsyncEventLostList {...props}
                                                                                cities={cities ? cities : []}
                                                                                showMessage={this.showMessage}
                                                                                userid={this.state.userid}
                                                            />}

                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/venue-filters/filtermodulelist"
                                                        component={props =>
                                                            <AsyncFilterModuleList {...props}
                                                                                   cities={cities ? cities : []}
                                                                                   showMessage={this.showMessage}
                                                                                   userid={this.state.userid}
                                                            />}

                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/enquiry/feedback"
                                                        component={AsyncFeedbackList}/>

                                                    <PrivateRoute
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={[]}
                                                        exact
                                                        path="/manager/leads/venuetargetleads"
                                                        component={AsyncVenueLeadTargetList}/>
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/venuetarget/venuetargetlist"
                                                        component={AsyncVenueLeadTargetList}/>
                                                    <PrivateRoute
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={[]}
                                                        path="/manager/notifications"
                                                        component={props =>
                                                            <AsyncAdminNotifications {...props}
                                                                                     user={this.state.user}/>}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["myleads:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/manager/enquiry/my-leads-board"
                                                        component={props =>
                                                            <AsyncAgentEnquiries {...props}
                                                                                 connectCall={this.connectCall}
                                                                                 userPermissions={this.state.userPermissions}
                                                                                 cities={cities ? cities : []}
                                                                                 user={this.state.user}
                                                                                 contents={this.state.contents}
                                                                                 agentid={this.state.agentid}
                                                                                 userid={this.state.userid}
                                                                                 agent={this.state.agent}/>}/>
                                                                                 <PrivateRoute
                                                                userPermissions={this.state.userPermissions}
                                                                shouldHavePermissions={["myleads:read"]}
                                                                isAuthenticated={this.state.isAuthenticated}
                                                                path="/manager/enquiry/leads-board"
                                                                component={props =>
                                                                    <AsyncAllEnquiries {...props}
                                                                                         userPermissions={this.state.userPermissions}
                                                                                         cities={cities ? cities : []}
                                                                                         contents={this.state.contents}
                                                                                         user={this.state.user}
                                                                                         agentid={this.state.agentid}
                                                                                         userid={this.state.userid}
                                                                                         agent={this.state.agent}/>}/>
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["myleads:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/manager/events/:id/update"
                                                        component={props =>
                                                            <AsyncAdminDashboard {...props}
                                                                                 showMessage={this.showMessage}
                                                                                 user={this.state.user}
                                                                                 agentid={this.state.agentid}
                                                                                 userid={this.state.userid}
                                                                                 agent={this.state.agent}/>}
                                                    />
                                                    <PrivateRoute
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["mytasks:read"]}
                                                        path="/manager/tasks/board"
                                                        component={props =>
                                                            <AsyncAgentLeadDashboard {...props}
                                                                                     showMessage={this.showMessage}
                                                                                     connectCall={this.connectCall}
                                                                                     userPermissions={this.state.userPermissions}
                                                                                     user={this.state.user}
                                                                                     agentid={this.state.agentid}
                                                                                     userid={this.state.userid}
                                                                                     cities={cities ? cities : []}
                                                                                     agent={this.state.agent}/>}/>
                                                    <PrivateRoute
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/manager/call/info"
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={[]}
                                                        component={props =>
                                                            <AsyncMyAgentCallData {...props}
                                                                                  showMessage={this.showMessage}
                                                                                  connectCall={this.connectCall}
                                                                                  userPermissions={this.state.userPermissions}
                                                                                  user={this.state.user}
                                                                                  agentid={this.state.agentid}
                                                                                  userid={this.state.userid}
                                                                                  cities={cities ? cities : []}
                                                                                  agent={this.state.agent}/>}/>
                                                    {/*admin*/}
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/users/list"
                                                        component={props =>
                                                            <AsyncUserList {...props}
                                                                           userPermissions={this.state.userPermissions}
                                                                           showMessage={this.showMessage}
                                                                           user={this.state.user}
                                                                           agentid={this.state.agentid}
                                                                           userid={this.state.userid}
                                                                           agent={this.state.agent}/>}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/users-search/list"
                                                        component={props =>
                                                            <AsyncUserSearchList {...props}
                                                                                 userPermissions={this.state.userPermissions}
                                                                                 showMessage={this.showMessage}
                                                                                 user={this.state.user}
                                                                                 agentid={this.state.agentid}
                                                                                 userid={this.state.userid}
                                                                                 agent={this.state.agent}/>}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/admin/role/list"
                                                        component={props =>
                                                            <AsyncRoleList {...props}
                                                                           userPermissions={this.state.userPermissions}
                                                                           showMessage={this.showMessage}
                                                                           user={this.state.user}
                                                                           agentid={this.state.agentid}
                                                                           userid={this.state.userid}
                                                                           agent={this.state.agent}/>}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={[]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/funnel/bd-board"
                                                        component={props =>
                                                            <AsyncAgentBdDashboard {...props}
                                                                                   userPermissions={this.state.userPermissions}
                                                                                   showMessage={this.showMessage}
                                                                                   user={this.state.user}
                                                                                   connectCall={this.connectCall}
                                                                                   agentid={this.state.agentid}
                                                                                   cities={cities ? cities : []}
                                                                                   userid={this.state.userid}
                                                                                   agent={this.state.agent}/>}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={[]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/funnel/corp-board"
                                                        component={props =>
                                                            <AsyncAgentCorpDashboard {...props}
                                                                                     connectCall={this.connectCall}
                                                                                     userPermissions={this.state.userPermissions}
                                                                                     showMessage={this.showMessage}
                                                                                     user={this.state.user}
                                                                                     cities={cities ? cities : []}
                                                                                     agentid={this.state.agentid}
                                                                                     userid={this.state.userid}
                                                                                     agent={this.state.agent}/>}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={[]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/funnel/feedback-board"
                                                        component={props =>
                                                            <AsyncAgentFeedbackDashboard {...props}
                                                                                         showMessage={this.showMessage}
                                                                                         connectCall={this.connectCall}
                                                                                         cities={cities ? cities : []}
                                                                                         userPermissions={this.state.userPermissions}
                                                                                         user={this.state.user}
                                                                                         agentid={this.state.agentid}
                                                                                         userid={this.state.userid}
                                                                                         agent={this.state.agent}/>}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={[]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/funnel/sales-board"
                                                        component={props =>
                                                            <AsyncSalesAgentQueueSystem {...props}
                                                                                        agent_type={'inside-sales'}
                                                                                        contents={this.state.contents}
                                                                                        connectCall={this.connectCall}
                                                                                        userPermissions={this.state.userPermissions}
                                                                                        showMessage={this.showMessage}
                                                                                        user={this.state.user}
                                                                                        cities={cities ? cities : []}
                                                                                        agentid={this.state.agentid}
                                                                                        userid={this.state.userid}
                                                                                        agent={this.state.agent}/>}
                                                    />
                                                    <PrivateRoute
                                                                userPermissions={this.state.userPermissions}
                                                                shouldHavePermissions={[]}
                                                                isAuthenticated={this.state.isAuthenticated}
                                                                exact
                                                                path="/manager/funnel/inside-sales"
                                                                component={props =>
                                                                    <AsyncSalesAgentQueueSystemV2 {...props}
                                                                                                  agent_type={INSIDE_SALES}
                                                                                                  contents={this.state.contents}
                                                                                                  userPermissions={this.state.userPermissions}
                                                                                                  showMessage={this.showMessage}
                                                                                                  user={this.state.user}
                                                                                                  cities={cities ? cities : []}
                                                                                                  agentid={this.state.agentid}
                                                                                                  userid={this.state.userid}
                                                                                                  agent={this.state.agent}/>}
                                                            />

                                                             <PrivateRoute
                                                                userPermissions={this.state.userPermissions}
                                                                shouldHavePermissions={[]}
                                                                isAuthenticated={this.state.isAuthenticated}
                                                                exact
                                                                path="/manager/funnel/visit-sales"
                                                                component={props =>
                                                                    <AsyncSalesAgentQueueSystemV2 {...props}
                                                                                                  agent_type={VISIT_SALES}
                                                                                                  contents={this.state.contents}
                                                                                                  userPermissions={this.state.userPermissions}
                                                                                                  showMessage={this.showMessage}
                                                                                                  user={this.state.user}
                                                                                                  cities={cities ? cities : []}
                                                                                                  agentid={this.state.agentid}
                                                                                                  userid={this.state.userid}
                                                                                                  agent={this.state.agent}/>}
                                                            />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/admin/rolegroup/list"
                                                        component={props =>
                                                            <AsyncRoleGroupList {...props}
                                                                                userPermissions={this.state.userPermissions}
                                                                                showMessage={this.showMessage}
                                                                                user={this.state.user}
                                                                                agentid={this.state.agentid}
                                                                                userid={this.state.userid}
                                                                                agent={this.state.agent}/>}
                                                    />

                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/admin/module/list"
                                                        component={props =>
                                                            <AsyncModuleList {...props}
                                                                             userPermissions={this.state.userPermissions}
                                                                             showMessage={this.showMessage}
                                                                             user={this.state.user}
                                                                             agentid={this.state.agentid}
                                                                             userid={this.state.userid}
                                                                             agent={this.state.agent}/>}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/admin/partner-app/module/list"
                                                        component={props =>
                                                            <AsyncPartnerAppModuleList {...props}
                                                                                       userPermissions={this.state.userPermissions}
                                                                                       showMessage={this.showMessage}
                                                                                       user={this.state.user}
                                                                                       agentid={this.state.agentid}
                                                                                       userid={this.state.userid}
                                                                                       agent={this.state.agent}/>}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/blog/blog-category/list"
                                                        component={props =>
                                                            <AsyncBlogCategoryList {...props}
                                                                                   userPermissions={this.state.userPermissions}
                                                                                   showMessage={this.showMessage}
                                                                                   user={this.state.user}
                                                                                   agentid={this.state.agentid}
                                                                                   userid={this.state.userid}
                                                                                   agent={this.state.agent}/>}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/approvals/approval-agent/list"
                                                        component={props =>
                                                            <AsyncApprovalAgentList {...props}
                                                                                    userPermissions={this.state.userPermissions}
                                                                                    showMessage={this.showMessage}
                                                                                    user={this.state.user}
                                                                                    agentid={this.state.agentid}
                                                                                    userid={this.state.userid}
                                                                                    agent={this.state.agent}/>}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/approvals/approval/list"
                                                        component={props =>
                                                            <AsyncApprovalList {...props}
                                                                               userPermissions={this.state.userPermissions}
                                                                               showMessage={this.showMessage}
                                                                               user={this.state.user}
                                                                               agentid={this.state.agentid}
                                                                               userid={this.state.userid}
                                                                               agent={this.state.agent}/>}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/approvals/partner-approval/list"
                                                        component={props =>
                                                            <AsyncPartnerApprovalList {...props}
                                                                                      userPermissions={this.state.userPermissions}
                                                                                      showMessage={this.showMessage}
                                                                                      user={this.state.user}
                                                                                      agentid={this.state.agentid}
                                                                                      userid={this.state.userid}
                                                                                      agent={this.state.agent}/>}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/partner/user/:userId/partner-account-permission"
                                                        component={props =>
                                                            <AsyncPartnerAccountPermissionUpdator {...props}
                                                                                                  userPermissions={this.state.userPermissions}
                                                                                                  showMessage={this.showMessage}
                                                                                                  user={this.state.user}
                                                                                                  agentid={this.state.agentid}
                                                                                                  userid={this.state.userid}
                                                                                                  cities={cities ? cities : []}
                                                                                                  agent={this.state.agent}/>}
                                                    />

                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/admin/permission/list"
                                                        component={props =>
                                                            <AsyncPermissionList {...props}
                                                                                 userPermissions={this.state.userPermissions}
                                                                                 showMessage={this.showMessage}
                                                                                 user={this.state.user}
                                                                                 agentid={this.state.agentid}
                                                                                 userid={this.state.userid}
                                                                                 agent={this.state.agent}/>}
                                                    />

                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/admin/role/:id/update"
                                                        component={props =>
                                                            <AsyncRoleUpdator {...props}
                                                                              showMessage={this.showMessage}
                                                                              user={this.state.user}
                                                                              agentid={this.state.agentid}
                                                                              userid={this.state.userid}
                                                                              agent={this.state.agent}/>}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/manager/user/:id/update"
                                                        component={props =>
                                                            <AsyncUserUpdate
                                                                {...props}
                                                                cities={cities ? cities : []}
                                                                showMessage={this.showMessage}
                                                                user={this.state.user}
                                                                agentid={this.state.agentid}
                                                                userid={this.state.userid}
                                                                agent={this.state.agent}
                                                            />
                                                        }
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/manager/agent/:id/update"
                                                        component={props =>
                                                            <AsyncAgentUpdator
                                                                {...props}
                                                                cities={cities ? cities : []}
                                                                showMessage={this.showMessage}
                                                                user={this.state.user}
                                                                agentid={this.state.agentid}
                                                                userid={this.state.userid}
                                                                agent={this.state.agent}
                                                            />
                                                        }
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/manager/agents/list"
                                                        component={props =>
                                                            <AsyncAgentList {...props}
                                                                            userPermissions={this.state.userPermissions}
                                                                            showMessage={this.showMessage}
                                                                            cities={cities ? cities : []}
                                                                            user={this.state.user}
                                                                            agentid={this.state.agentid}
                                                                            userid={this.state.userid}
                                                                            agent={this.state.agent}/>}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/manager/agentonline/list"
                                                        component={props =>
                                                            <AsyncAgentOnlineList {...props}
                                                                                  userPermissions={this.state.userPermissions}
                                                                                  showMessage={this.showMessage}
                                                                                  cities={cities ? cities : []}
                                                                                  user={this.state.user}
                                                                                  agentid={this.state.agentid}
                                                                                  userid={this.state.userid}
                                                                                  agent={this.state.agent}/>}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/manager/agents/call-data"
                                                        component={props =>
                                                            <AsyncAgentCallData {...props}
                                                                                userPermissions={this.state.userPermissions}
                                                                                showMessage={this.showMessage}
                                                                                cities={cities ? cities : []}
                                                                                user={this.state.user}
                                                                                agentid={this.state.agentid}
                                                                                userid={this.state.userid}
                                                                                agent={this.state.agent}/>}
                                                    />
                                                    {/*venueonboarding*/}
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["onboarding:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/venue/onboarding-list"
                                                        component={props =>
                                                            <AsyncPreVenueList {...props}
                                                                               userPermissions={this.state.userPermissions}
                                                                               showMessage={this.showMessage}
                                                                               cities={cities ? cities : []}
                                                                               user={this.state.user}
                                                                               agentid={this.state.agentid}
                                                                               userid={this.state.userid}
                                                                               agent={this.state.agent}/>}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["venueonboardings:update"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/manager/onboarding-venue/:id/update"
                                                        component={props =>
                                                            <AsyncPreVenue {...props}
                                                                           showMessage={this.showMessage}
                                                                           cities={cities ? cities : []}
                                                                           user={this.state.user}
                                                                           agentid={this.state.agentid}
                                                                           userid={this.state.userid}
                                                                           agent={this.state.agent}/>}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["onboarding:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/venue/tracker-list"
                                                        component={props =>
                                                            <AsyncVenueTrackerList
                                                                showMessage={this.showMessage}
                                                                cities={cities ? cities : []}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={[]}
                                                        path="/manager/venues/:id/tracker-page"
                                                        component={props =>
                                                            <AsyncVenueTrackerPage {...props}
                                                                                   agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/photographer/onboarding-list"
                                                        component={props =>
                                                            <AsyncPrephotographerList {...props}
                                                                                      showMessage={this.showMessage}
                                                                                      cities={cities ? cities : []}
                                                                                      user={this.state.user}
                                                                                      agentid={this.state.agentid}
                                                                                      userid={this.state.userid}
                                                                                      agent={this.state.agent}/>}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/manager/onboarding-photographer/:id/update"
                                                        component={props =>
                                                            <AsyncPrephotographer {...props}
                                                                                  showMessage={this.showMessage}
                                                                                  cities={cities ? cities : []}
                                                                                  user={this.state.user}
                                                                                  agentid={this.state.agentid}
                                                                                  userid={this.state.userid}
                                                                                  agent={this.state.agent}/>}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/photographers/tracker-list"
                                                        component={AsyncVendorTrackerList}/>
                                                    <PrivateRoute
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={[]}
                                                        path="/manager/photographer/:id/tracker-page"
                                                        component={props =>
                                                            <AsyncVendorTrackerPage {...props}
                                                                                    agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    {/*venues*/}
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["bd-role:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/access/list"
                                                        component={props =>
                                                            <AsyncAdminAccessList {...props}
                                                                                  showMessage={this.showMessage}
                                                                                  user={this.state.user}
                                                                                  agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/venue/:id/venue-leads"
                                                        component={props =>
                                                            <AsyncVenueLeadList {...props}
                                                                                cities={cities}
                                                                                showMessage={this.showMessage}
                                                                                user={this.state.user}
                                                                                agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    {/* <PrivateRoute */}
                                                    {/*    userPermissions={this.state.userPermissions}*/}
                                                    {/*    shouldHavePermissions={["venues:read"]}*/}
                                                    {/*    isAuthenticated={this.state.isAuthenticated} exact*/}
                                                    {/*    path="/manager/venue/list"*/}
                                                    {/*    component={props =>*/}
                                                    {/*        <AsyncAdminVenueList {...props}*/}
                                                    {/*                             showMessage={this.showMessage}*/}
                                                    {/*                             userPermissions={this.state.userPermissions}*/}
                                                    {/*                             user={this.state.user}*/}
                                                    {/*                             agentid={this.state.agentid}*/}
                                                    {/*        />}*/}
                                                    {/*/> */}
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["venues:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/adleads/list"
                                                        component={props =>
                                                            <AsyncAdLeadsVenueList {...props}
                                                                                   showMessage={this.showMessage}
                                                                                   userPermissions={this.state.userPermissions}
                                                                                   user={this.state.user}
                                                                                   agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["venues:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/venue/expert-list"
                                                        component={props =>
                                                            <AsyncVenueExpertList {...props}
                                                                                  showMessage={this.showMessage}
                                                                                  userPermissions={this.state.userPermissions}
                                                                                  user={this.state.user}
                                                                                  agentid={this.state.agentid}
                                                                                  cities={cities ? cities : []}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["venues:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/venue/drive-list"
                                                        component={props =>
                                                            <AsyncVenueDriveList {...props}
                                                                                 showMessage={this.showMessage}
                                                                                 userPermissions={this.state.userPermissions}
                                                                                 user={this.state.user}
                                                                                 agentid={this.state.agentid}
                                                                                 cities={cities}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["venues:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/notfoundvenue/list"
                                                        component={props =>
                                                            <NotFoundVenueList {...props}
                                                                               userPermissions={this.state.userPermissions}
                                                                               user={this.state.user}
                                                                               showMessage={this.showMessage}
                                                                               agentid={this.state.agentid}
                                                                               cities={cities}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["venues:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/futureevent/list"
                                                        component={props =>
                                                            <FutureEventList {...props}
                                                                             userPermissions={this.state.userPermissions}
                                                                             user={this.state.user}
                                                                             agentid={this.state.agentid}
                                                                             connectCall={this.connectCall}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["mytasks:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/myfeedback/list"
                                                        component={props =>
                                                            <AsyncMyFeedbackList {...props}
                                                                                 showMessage={this.showMessage}
                                                                                 userPermissions={this.state.userPermissions}
                                                                                 user={this.state.user}
                                                                                 agentid={this.state.agentid}
                                                                                 connectCall={this.connectCall}
                                                                                 cities={cities}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/venue/report"
                                                        component={props =>
                                                            <AsyncVenueReport {...props}
                                                                              cities={cities}
                                                                              showMessage={this.showMessage}
                                                                              user={this.state.user}
                                                                              agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["mytasks:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/salescrm/bundleset/list"
                                                        component={props =>
                                                            <AsyncBundleSetList {...props}
                                                                                cities={cities}
                                                                                showMessage={this.showMessage}
                                                                                user={this.state.user}
                                                                                agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["mytasks:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/salescrm/bundleset/:id/update"
                                                        component={props =>
                                                            <AsyncBundleSetUpdator {...props}
                                                                                   cities={cities}
                                                                                   showMessage={this.showMessage}
                                                                                   user={this.state.user}
                                                                                   agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["mytasks:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/crm/funnelsystem/list"
                                                        component={props =>
                                                            <AsyncFunnelSystemList {...props}
                                                                                   cities={cities}
                                                                                   showMessage={this.showMessage}
                                                                                   user={this.state.user}
                                                                                   agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["mytasks:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/crm/funnel/:id/update"
                                                        component={props =>
                                                            <AsyncFunnelUpdator {...props}
                                                                                cities={cities}
                                                                                showMessage={this.showMessage}
                                                                                user={this.state.user}
                                                                                agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <Redirect
                                                        exact
                                                        from="/manager/venue/featured"
                                                        to={"/manager/business-development/venue-partners"}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["bd-role:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/business-development/venue-partners"
                                                        component={props =>
                                                            <AsyncFeaturedVenueList {...props}
                                                                                    cities={cities}
                                                                                    showMessage={this.showMessage}
                                                                                    user={this.state.user}
                                                                                    agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/venue/venue-leads"
                                                        component={props =>
                                                            <AsyncVenueBundlesList {...props}
                                                                                   cities={cities}
                                                                                   showMessage={this.showMessage}
                                                                                   user={this.state.user}
                                                                                   agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/venue/algo-test"
                                                        component={props =>
                                                            <AsyncAlgoGraph {...props}
                                                                            cities={cities}
                                                                            showMessage={this.showMessage}
                                                                            user={this.state.user}
                                                                            agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/mypartner-approval/list"
                                                        component={props =>
                                                            <AsyncMyPartnerApproval {...props}
                                                                                    cities={cities}
                                                                                    showMessage={this.showMessage}
                                                                                    user={this.state.user}
                                                                                    agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["venue-finance:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/venues/:city/:url/finance/update"
                                                        component={props =>
                                                            <AsyncVenueFinanceUpdator {...props}
                                                                                      showMessage={this.showMessage}
                                                                                      user={this.state.user}
                                                                                      agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["venue-contact:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/venues/:city/:url/contacts/update"
                                                        component={props =>
                                                            <AsyncVenueContactUpdator {...props}
                                                                                      showMessage={this.showMessage}
                                                                                      user={this.state.user}
                                                                                      agentid={this.state.agentid}
                                                            />}
                                                    />

                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/mehendi-artists/:url/update"
                                                        component={props =>
                                                            <AsyncVendorMehendiArtistUpdator {...props}
                                                                                             showMessage={this.showMessage}
                                                                                             user={this.state.user}
                                                                                             agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/makeup-artists/:url/update"
                                                        component={props =>
                                                            <AsyncVendorMakeupArtistUpdator {...props}
                                                                                            showMessage={this.showMessage}
                                                                                            user={this.state.user}
                                                                                            agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/photographers/:url/update"
                                                        component={props =>
                                                            <AsyncVendorPhotographerUpdator {...props}
                                                                                            showMessage={this.showMessage}
                                                                                            user={this.state.user}
                                                                                            agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["venues:update"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/venues/:city/:url/update"
                                                        component={props =>
                                                            <AsyncVenueUpdator {...props}
                                                                               showMessage={this.showMessage}
                                                                               user={this.state.user}
                                                                               agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["venues:update"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/venues/data-transfer"
                                                        component={props =>
                                                            <AsyncVenueToVenueDataTransfer {...props}
                                                                                           showMessage={this.showMessage}
                                                                                           user={this.state.user}
                                                                                           agentid={this.state.agentid}
                                                                                           cities={cities}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["venues:update"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/venues/bulk-data-updator"
                                                        component={props =>
                                                            <AsyncVenueBulkDataUpdator {...props}
                                                                                       showMessage={this.showMessage}
                                                                                       user={this.state.user}
                                                                                       agentid={this.state.agentid}
                                                                                       cities={cities}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["venues:update"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/venues/faq-list"
                                                        component={props =>
                                                            <AsyncVenueFaqList {...props}
                                                                               showMessage={this.showMessage}
                                                                               userPermissions={this.state.userPermissions}
                                                                               user={this.state.user}
                                                                               agentid={this.state.agentid}
                                                                               cities={cities}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["venue-image:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/venues/:city/:url/images/update"
                                                        component={props =>
                                                            <AsyncVenueImageUpdator {...props}
                                                                                    showMessage={this.showMessage}
                                                                                    user={this.state.user}
                                                                                    agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["venue-description:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/venues/:city/:url/description/edit"
                                                        component={props =>
                                                            <AsyncVenueDescriptionUpdator {...props}
                                                                                          showMessage={this.showMessage}
                                                                                          user={this.state.user}
                                                                                          agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["marketing:update"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/venues/:city/:url/seo/edit"
                                                        component={props =>
                                                            <AsyncVenueSEOUpdator {...props}
                                                                                  showMessage={this.showMessage}
                                                                                  user={this.state.user}
                                                                                  agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["venue-package:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/venues/:city/:url/packages/update"
                                                        component={props =>
                                                            <AsyncVenueMenuUpdator {...props}
                                                                                   showMessage={this.showMessage}
                                                                                   user={this.state.user}
                                                                                   userid={this.state.userid}
                                                                                   agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/admin/role/:id/update"
                                                        component={props =>
                                                            <AsyncRoleUpdator {...props}
                                                                              showMessage={this.showMessage}
                                                                              user={this.state.user}
                                                                              agentid={this.state.agentid}
                                                                              userid={this.state.userid}
                                                                              agent={this.state.agent}/>}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/manager/user/:id/update"
                                                        component={props =>
                                                            <AsyncUserUpdate
                                                                {...props}
                                                                cities={cities ? cities : []}
                                                                showMessage={this.showMessage}
                                                                user={this.state.user}
                                                                agentid={this.state.agentid}
                                                                userid={this.state.userid}
                                                                agent={this.state.agent}
                                                            />
                                                        }
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/manager/agent/:id/update"
                                                        component={props =>
                                                            <AsyncAgentUpdator
                                                                {...props}
                                                                cities={cities ? cities : []}
                                                                showMessage={this.showMessage}
                                                                user={this.state.user}
                                                                agentid={this.state.agentid}
                                                                userid={this.state.userid}
                                                                agent={this.state.agent}
                                                            />
                                                        }
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/manager/corporate/agent/:id/update"
                                                        component={props =>
                                                            <AsyncCorporateRuleUpdator
                                                                {...props}
                                                                cities={cities ? cities : []}
                                                                showMessage={this.showMessage}
                                                                user={this.state.user}
                                                                agentid={this.state.agentid}
                                                                userid={this.state.userid}
                                                                agent={this.state.agent}
                                                            />
                                                        }
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/manager/corporate/signups"
                                                        component={props =>
                                                            <AsyncCorporateLeadList
                                                                {...props}
                                                                cities={cities ? cities : []}
                                                                showMessage={this.showMessage}
                                                                user={this.state.user}
                                                                agentid={this.state.agentid}
                                                                userid={this.state.userid}
                                                                agent={this.state.agent}
                                                            />
                                                        }
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/manager/corporate/offer/:id/update"
                                                        component={props =>
                                                            <AsyncOfferUpdator
                                                                {...props}
                                                                cities={cities ? cities : []}
                                                                showMessage={this.showMessage}
                                                                user={this.state.user}
                                                                agentid={this.state.agentid}
                                                                userid={this.state.userid}
                                                                agent={this.state.agent}
                                                            />
                                                        }
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/manager/corporate/redemption/open"
                                                        component={props =>
                                                            <AsyncOpenRedemptionList
                                                                {...props}
                                                                cities={cities ? cities : []}
                                                                showMessage={this.showMessage}
                                                                user={this.state.user}
                                                                agentid={this.state.agentid}
                                                                userid={this.state.userid}
                                                                agent={this.state.agent}
                                                            />
                                                        }
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/manager/corporate/redemption/list"
                                                        component={props =>
                                                            <AsyncRedemptionList
                                                                {...props}
                                                                userPermissions={this.state.userPermissions}
                                                                cities={cities ? cities : []}
                                                                showMessage={this.showMessage}
                                                                user={this.state.user}
                                                                agentid={this.state.agentid}
                                                                userid={this.state.userid}
                                                                agent={this.state.agent}
                                                            />
                                                        }
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/manager/corporate/redemption"
                                                        component={props =>
                                                            <AsyncRedemptionList
                                                                {...props}
                                                                userPermissions={this.state.userPermissions}
                                                                cities={cities ? cities : []}
                                                                showMessage={this.showMessage}
                                                                user={this.state.user}
                                                                agentid={this.state.agentid}
                                                                userid={this.state.userid}
                                                                agent={this.state.agent}
                                                            />
                                                        }
                                                    />

                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/manager/corporate/offers"
                                                        component={props =>
                                                            <AsyncOfferList
                                                                {...props}
                                                                cities={cities ? cities : []}
                                                                showMessage={this.showMessage}
                                                                user={this.state.user}
                                                                agentid={this.state.agentid}
                                                                userid={this.state.userid}
                                                                agent={this.state.agent}
                                                            />
                                                        }
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/manager/agents/list"
                                                        component={props =>
                                                            <AsyncAgentList {...props}
                                                                            userPermissions={this.state.userPermissions}
                                                                            showMessage={this.showMessage}
                                                                            cities={cities ? cities : []}
                                                                            user={this.state.user}
                                                                            agentid={this.state.agentid}
                                                                            userid={this.state.userid}
                                                                            agent={this.state.agent}/>}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/manager/purplepatch/lead/list"
                                                        component={props =>
                                                            <AsyncPurplePatchLeadList {...props}
                                                                                      userPermissions={this.state.userPermissions}
                                                                                      showMessage={this.showMessage}
                                                                                      cities={cities ? cities : []}
                                                                                      user={this.state.user}
                                                                                      agentid={this.state.agentid}
                                                                                      userid={this.state.userid}
                                                                                      agent={this.state.agent}/>}
                                                    />

                                                    {/*venueonboarding*/}
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["venueonboardings:update"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/manager/onboarding-venue/:id/update"
                                                        component={props =>
                                                            <AsyncPreVenue {...props}
                                                                           showMessage={this.showMessage}
                                                                           cities={cities ? cities : []}
                                                                           user={this.state.user}
                                                                           agentid={this.state.agentid}
                                                                           userid={this.state.userid}
                                                                           agent={this.state.agent}/>}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["onboarding:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/venue/tracker-list"
                                                        component={AsyncVenueTrackerList}/>
                                                    <PrivateRoute
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={[]}
                                                        path="/manager/venues/:id/tracker-page"
                                                        component={props =>
                                                            <AsyncVenueTrackerPage {...props}
                                                                                   agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/photographer/onboarding-list"
                                                        component={props =>
                                                            <AsyncPrephotographerList {...props}
                                                                                      showMessage={this.showMessage}
                                                                                      cities={cities ? cities : []}
                                                                                      user={this.state.user}
                                                                                      agentid={this.state.agentid}
                                                                                      userid={this.state.userid}
                                                                                      agent={this.state.agent}/>}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/manager/onboarding-photographer/:id/update"
                                                        component={props =>
                                                            <AsyncPrephotographer {...props}
                                                                                  showMessage={this.showMessage}
                                                                                  cities={cities ? cities : []}
                                                                                  user={this.state.user}
                                                                                  agentid={this.state.agentid}
                                                                                  userid={this.state.userid}
                                                                                  agent={this.state.agent}/>}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/photographers/tracker-list"
                                                        component={AsyncVendorTrackerList}/>
                                                    <PrivateRoute
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={[]}
                                                        path="/manager/photographer/:id/tracker-page"
                                                        component={props =>
                                                            <AsyncVendorTrackerPage {...props}
                                                                                    agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    {/*venues*/}
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["bd-role:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/access/list"
                                                        component={props =>
                                                            <AsyncAdminAccessList {...props}
                                                                                  showMessage={this.showMessage}
                                                                                  user={this.state.user}
                                                                                  agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["bd-role:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/corporate/account/list"
                                                        component={props =>
                                                            <AsyncCorporateUsersList {...props}
                                                                                     userPermissions={this.state.userPermissions}
                                                                                     cities={cities ? cities : []}
                                                                                     user={this.state.user}
                                                                                     agentid={this.state.agentid}
                                                                                     userid={this.state.userid}
                                                                                     agent={this.state.agent}
                                                                                     showMessage={this.showMessage}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["bd-role:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/corporate/event/list"
                                                        component={props =>
                                                            <AsyncCorporateAllEventList {...props}
                                                                                        userPermissions={this.state.userPermissions}
                                                                                        cities={cities ? cities : []}
                                                                                        user={this.state.user}
                                                                                        agentid={this.state.agentid}
                                                                                        userid={this.state.userid}
                                                                                        agent={this.state.agent}
                                                                                        showMessage={this.showMessage}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["bd-role:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/corporate/event/won-list"
                                                        component={props =>
                                                            <AsyncCorporateEventWonList {...props}
                                                                                        userPermissions={this.state.userPermissions}
                                                                                        cities={cities ? cities : []}
                                                                                        user={this.state.user}
                                                                                        agentid={this.state.agentid}
                                                                                        userid={this.state.userid}
                                                                                        agent={this.state.agent}
                                                                                        showMessage={this.showMessage}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["bd-role:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/corporate/coupons"
                                                        component={props =>
                                                            <AsyncCorporateCouponList {...props}
                                                                                      userPermissions={this.state.userPermissions}
                                                                                      cities={cities ? cities : []}
                                                                                      user={this.state.user}
                                                                                      agentid={this.state.agentid}
                                                                                      userid={this.state.userid}
                                                                                      agent={this.state.agent}
                                                                                      showMessage={this.showMessage}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["bd-role:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/corporate/referralcode"
                                                        component={props =>
                                                            <AsyncReferralCodeList {...props}
                                                                                   userPermissions={this.state.userPermissions}
                                                                                   cities={cities ? cities : []}
                                                                                   user={this.state.user}
                                                                                   agentid={this.state.agentid}
                                                                                   userid={this.state.userid}
                                                                                   agent={this.state.agent}
                                                                                   showMessage={this.showMessage}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["bd-role:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/corporate-coupon/coupon/:id/update"
                                                        component={props =>
                                                            <AsyncCorporateCouponUpdator {...props}
                                                                                         userPermissions={this.state.userPermissions}
                                                                                         cities={cities ? cities : []}
                                                                                         user={this.state.user}
                                                                                         agentid={this.state.agentid}
                                                                                         userid={this.state.userid}
                                                                                         agent={this.state.agent}
                                                                                         showMessage={this.showMessage}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["bd-role:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/business-development/agent/:id/update"
                                                        component={props =>
                                                            <AsyncBizRuleUpdator {...props}
                                                                                 userPermissions={this.state.userPermissions}
                                                                                 cities={cities ? cities : []}
                                                                                 user={this.state.user}
                                                                                 agentid={this.state.agentid}
                                                                                 userid={this.state.userid}
                                                                                 agent={this.state.agent}
                                                                                 showMessage={this.showMessage}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/vendor/photographers-list"
                                                        component={props =>
                                                            <AsyncVendorPhotographerList {...props}
                                                                                         cities={cities}
                                                                                         showMessage={this.showMessage}
                                                                                         user={this.state.user}
                                                                                         agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/vendor/mehendi-artists-list"
                                                        component={props =>
                                                            <AsyncVendorMehendiList {...props}
                                                                                    cities={cities}
                                                                                    showMessage={this.showMessage}
                                                                                    user={this.state.user}
                                                                                    agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/vendor/makeup-artists-list"
                                                        component={props =>
                                                            <AsyncVendorMakeupList {...props}
                                                                                   cities={cities}
                                                                                   showMessage={this.showMessage}
                                                                                   user={this.state.user}
                                                                                   agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/venue/:id/venue-leads"
                                                        component={props =>
                                                            <AsyncVenueLeadList {...props}
                                                                                cities={cities}
                                                                                showMessage={this.showMessage}
                                                                                user={this.state.user}
                                                                                agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["venues:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/venue/list"
                                                        component={props =>
                                                            <AsyncAdminVenueList {...props}
                                                                                 showMessage={this.showMessage}
                                                                                 userPermissions={this.state.userPermissions}
                                                                                 user={this.state.user}
                                                                                 cities={cities}
                                                                                 agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["venues:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/venue/content"
                                                        component={props =>
                                                            <AsyncVenueContentList {...props}
                                                                                   showMessage={this.showMessage}
                                                                                   userPermissions={this.state.userPermissions}
                                                                                   user={this.state.user}
                                                                                   agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/venue/report"
                                                        component={props =>
                                                            <AsyncVenueReport {...props}
                                                                              cities={cities}
                                                                              showMessage={this.showMessage}
                                                                              user={this.state.user}
                                                                              agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <Redirect
                                                        exact
                                                        from="/manager/venue/featured"
                                                        to={"/manager/business-development/venue-partners"}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["bd-role:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/business-development/venue-partners"
                                                        component={props =>
                                                            <AsyncFeaturedVenueList {...props}
                                                                                    cities={cities}
                                                                                    showMessage={this.showMessage}
                                                                                    user={this.state.user}
                                                                                    agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/venue/venue-leads"
                                                        component={props =>
                                                            <AsyncVenueBundlesList {...props}
                                                                                   cities={cities}
                                                                                   showMessage={this.showMessage}
                                                                                   user={this.state.user}
                                                                                   agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/venue/algo-test"
                                                        component={props =>
                                                            <AsyncAlgoGraph {...props}
                                                                            cities={cities}
                                                                            showMessage={this.showMessage}
                                                                            user={this.state.user}
                                                                            agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["venue-finance:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/venues/:city/:url/finance/update"
                                                        component={props =>
                                                            <AsyncVenueFinanceUpdator {...props}
                                                                                      showMessage={this.showMessage}
                                                                                      user={this.state.user}
                                                                                      agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["venue-contact:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/venues/:city/:url/contacts/update"
                                                        component={props =>
                                                            <AsyncVenueContactUpdator {...props}
                                                                                      showMessage={this.showMessage}
                                                                                      user={this.state.user}
                                                                                      agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["venues:update"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/venues/:city/:url/update"
                                                        component={props =>
                                                            <AsyncVenueUpdator {...props}
                                                                               showMessage={this.showMessage}
                                                                               user={this.state.user}
                                                                               agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["venue-image:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/venues/:city/:url/images/update"
                                                        component={props =>
                                                            <AsyncVenueImageUpdator {...props}
                                                                                    showMessage={this.showMessage}
                                                                                    user={this.state.user}
                                                                                    agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["venue-description:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/venues/:city/:url/description/edit"
                                                        component={props =>
                                                            <AsyncVenueDescriptionUpdator {...props}
                                                                                          showMessage={this.showMessage}
                                                                                          user={this.state.user}
                                                                                          agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["venue-review:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/venues/:city/:url/review/add"
                                                        component={props =>
                                                            <AsyncVenueReviewUpdator {...props}
                                                                                     showMessage={this.showMessage}
                                                                                     user={this.state.user}
                                                                                     agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["venues:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/manager/venues/expert/:id/update"
                                                        component={props =>
                                                            <AsyncVenueExpertUpdator {...props}
                                                                                     cities={cities ? cities : []}
                                                                                     showMessage={this.showMessage}
                                                                                     user={this.state.user}
                                                                                     agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["venue-package:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/venues/:city/:url/packages/update"
                                                        component={props =>
                                                            <AsyncVenueMenuUpdator {...props}
                                                                                   showMessage={this.showMessage}
                                                                                   user={this.state.user}
                                                                                   agentid={this.state.agentid}
                                                            />}
                                                    />

                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["expressbook:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/expressbook/birthday/list"
                                                        component={props =>
                                                            <AccessControl
                                                                permissions={this.state.userPermissions}
                                                                shouldHave={['expressbook:read']}
                                                            >
                                                                <AsyncBdayPackList {...props}
                                                                                   showMessage={this.showMessage}
                                                                                   user={this.state.user}
                                                                                   agentid={this.state.agentid}
                                                                />
                                                            </AccessControl>
                                                        }
                                                    />


                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/manager/expressbook/birthday/:id/update"
                                                        component={props =>
                                                            <AccessControl
                                                                permissions={this.state.userPermissions}
                                                                shouldHave={['expressbook:read']}
                                                            >
                                                                <AsyncBdayPackUpdator {...props}
                                                                                      cities={cities ? cities : []}
                                                                                      showMessage={this.showMessage}
                                                                                      user={this.state.user}
                                                                                      agentid={this.state.agentid}
                                                                />
                                                            </AccessControl>
                                                        }
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["expressbook:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/expressbook/party/list"
                                                        component={props =>
                                                            <AsyncPartyPackList {...props}

                                                                                showMessage={this.showMessage}
                                                                                user={this.state.user}
                                                                                agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["expressbook:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/expressbook/package-buyers/list"
                                                        component={AsyncPackageBuyerList}/>
                                                    <PrivateRoute
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={[]}
                                                        path="/manager/expressbook/party/:id/update"
                                                        component={props =>
                                                            <AsyncPartyPackUpdator {...props}

                                                                                   showMessage={this.showMessage}
                                                                                   user={this.state.user}
                                                                                   agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    {/*services*/}
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/services/city/list"
                                                        component={props =>
                                                            <AsyncCityList {...props}
                                                                           userPermissions={this.state.userPermissions}
                                                                           showMessage={this.showMessage}
                                                                           user={this.state.user}
                                                                           agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/lead/filter/list"
                                                        component={props =>
                                                            <AsyncFilterList {...props}
                                                                             showMessage={this.showMessage}
                                                                             user={this.state.user}
                                                                             agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/venue-filters/filter/list"
                                                        component={props =>
                                                            <AsyncVenueSearchFilterList {...props}
                                                                                        showMessage={this.showMessage}
                                                                                        user={this.state.user}
                                                                                        agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/services/filter/:id/update"
                                                        component={props =>
                                                            <AsyncFilterUpdator {...props}
                                                                                showMessage={this.showMessage}
                                                                                user={this.state.user}
                                                                                agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/venue-filters/filter/:id/update"
                                                        component={props =>
                                                            <AsyncVenueSearchFilterUpdator {...props}
                                                                                           showMessage={this.showMessage}
                                                                                           user={this.state.user}
                                                                                           agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/services/sms/templates"
                                                        component={props =>
                                                            <AsyncSmsTemplatesList {...props}
                                                                                   userPermissions={this.state.userPermissions}
                                                                                   showMessage={this.showMessage}
                                                                                   user={this.state.user}
                                                                                   agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/services/pdf/templates"
                                                        component={props =>
                                                            <AsyncPdfTemplatesList {...props}
                                                                                   userPermissions={this.state.userPermissions}
                                                                                   showMessage={this.showMessage}
                                                                                   user={this.state.user}
                                                                                   agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/manager/services/whatsapp/templates"
                                                        component={props =>
                                                            <AsyncWhatsappTemplatesList {...props}
                                                                                        showMessage={this.showMessage}
                                                                                        user={this.state.user}
                                                                                        agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/services/sms/tasks"
                                                        component={props =>
                                                            <AsyncSmsTaskList {...props}

                                                                              showMessage={this.showMessage}
                                                                              user={this.state.user}
                                                                              agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/manager/services/whatsapp/tasks"
                                                        component={props =>
                                                            <AsyncWhatsappTaskList {...props}
                                                                                   showMessage={this.showMessage}
                                                                                   user={this.state.user}
                                                                                   agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/services/email/templates"
                                                        component={props =>
                                                            <AsyncEmailTemplateList {...props}
                                                                                    userPermissions={this.state.userPermissions}
                                                                                    showMessage={this.showMessage}
                                                                                    user={this.state.user}
                                                                                    agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/services/summary/templates"
                                                        component={props =>
                                                            <AsyncSummaryTemplateList {...props}
                                                                                      userPermissions={this.state.userPermissions}
                                                                                      showMessage={this.showMessage}
                                                                                      user={this.state.user}
                                                                                      agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/services/searchlink-templates/list"
                                                        component={props =>
                                                            <AsyncSearchlinkSummaryTemplate {...props}
                                                                                            userPermissions={this.state.userPermissions}
                                                                                            showMessage={this.showMessage}
                                                                                            user={this.state.user}
                                                                                            agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/services/email/:id/template-edit"
                                                        component={props =>
                                                            <AsyncEmailTemplatePreview {...props}
                                                                                       userPermissions={this.state.userPermissions}
                                                                                       showMessage={this.showMessage}
                                                                                       user={this.state.user}
                                                                                       agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/services/email/tasks"
                                                        component={props =>
                                                            <AsyncEmailTaskList {...props}
                                                                                showMessage={this.showMessage}
                                                                                user={this.state.user}
                                                                                agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/services/content/list"
                                                        component={props =>
                                                            <AsyncContentList {...props}
                                                                              userPermissions={this.state.userPermissions}
                                                                              showMessage={this.showMessage}
                                                                              user={this.state.user}
                                                                              agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/content/promocode/list"
                                                        component={props =>
                                                            <AsyncPromoCodeList {...props}
                                                                                userPermissions={this.state.userPermissions}
                                                                                showMessage={this.showMessage}
                                                                                user={this.state.user}
                                                                                agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/content/associatedsubareas/list"
                                                        component={props =>
                                                            <AsyncAssociatedSubareaList {...props}
                                                                                        userPermissions={this.state.userPermissions}
                                                                                        showMessage={this.showMessage}
                                                                                        user={this.state.user}
                                                                                        cities={this.state.cities}
                                                                                        agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}

                                                        exact
                                                        path="/manager/content/associatedsubareas/list"
                                                        component={props =>
                                                            <AsyncAssociatedSubareaList {...props}
                                                                                        userPermissions={this.state.userPermissions}
                                                                                        showMessage={this.showMessage}
                                                                                        user={this.state.user}
                                                                                        cities={this.state.cities}
                                                                                        agentid={this.state.agentid}
                                                            />}
                                                    />

                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/manager/content/associatedsubareas/:id/update"
                                                        component={props =>
                                                            <AsyncAssociatedSubareaUpdator {...props}
                                                                                           cities={this.state.cities}
                                                                                           showMessage={this.showMessage}
                                                                                           userid={this.state.userid}
                                                            />}/>

                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/manager/services/content/:id/update"
                                                        component={props =>
                                                            <AsyncContentUpdator {...props}
                                                                                 showMessage={this.showMessage}
                                                                                 userid={this.state.userid}

                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/manager/services/scripts/file-upload"
                                                        component={props =>
                                                            <AsyncUploader {...props}
                                                                           userid={this.state.userid}
                                                                           showMessage={this.showMessage}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/manager/services/short-url/list"
                                                        component={props =>
                                                            <AsyncShortUrlList {...props}
                                                                               userid={this.state.userid}
                                                                               showMessage={this.showMessage}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/services/keywords/list"
                                                        component={props =>
                                                            <AsyncKeywordList {...props}
                                                                              userPermissions={this.state.userPermissions}
                                                                              cities={cities}
                                                                              user={this.state.user}
                                                                              userid={this.state.userid}
                                                                              showMessage={this.showMessage}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/services/paid-partner/list"
                                                        component={props =>
                                                            <AsyncAdminFeaturedVenueList {...props}
                                                                                         userPermissions={this.state.userPermissions}
                                                                                         cities={cities}
                                                                                         user={this.state.user}
                                                                                         userid={this.state.userid}
                                                                                         showMessage={this.showMessage}
                                                            />}
                                                    />
                                                    {/*subscriptions*/}
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["subscriptions:create"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/services/subscriptions/create"
                                                        component={props =>
                                                            <AsyncVenueSubscriptionCreator {...props}
                                                                                           cities={cities}
                                                                                           userid={this.state.userid}
                                                                                           showMessage={this.showMessage}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["subscriptions:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/services/subscriptions/list"
                                                        component={AsyncSubscriptionList}/>
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["plans:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/services/plans/list"
                                                        component={AsyncPlanList}/>
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["payments:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/services/payments/list"
                                                        component={AsyncPaymentList}/>
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["cheques:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/services/cheques/list"
                                                        component={AsyncChequesList}/>
                                                    {/*content*/}
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/content/occasions/list"
                                                        component={AsyncOccasionList}/>

                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["eventquality:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/content/eventquality/list"
                                                        component={props =>
                                                            <AsyncEventQualityList {...props}
                                                                                   userPermissions={this.state.userPermissions}
                                                                                   cities={cities}
                                                                                   userid={this.state.userid}
                                                                                   showMessage={this.showMessage}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/content/eventpriority/list"
                                                        component={props =>
                                                            <AsyncEventPriorityList {...props}
                                                                                    cities={cities}
                                                                                    userid={this.state.userid}
                                                                                    showMessage={this.showMessage}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/content/extrapermissions/list"
                                                        component={props =>
                                                            <AsyncExtraPermissionsList {...props}
                                                                                       userPermissions={this.state.userPermissions}
                                                                                       showMessage={this.showMessage}
                                                                                       userid={this.state.userid}
                                                            />}

                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/manager/content/constants/:id/update"
                                                        component={props =>
                                                            <AsyncConstantUpdator {...props}
                                                                                  showMessage={this.showMessage}
                                                                                  userid={this.state.userid}
                                                            />}/>
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/content/cuisines/list"
                                                        component={props =>
                                                            <AsyncCuisineList {...props}
                                                                              userPermissions={this.state.userPermissions}
                                                                              showMessage={this.showMessage}
                                                                              userid={this.state.userid}
                                                            />}/>
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/content/facilities/list"
                                                        component={props =>
                                                            <AsyncFacilityList {...props}
                                                                               userPermissions={this.state.userPermissions}
                                                                               showMessage={this.showMessage}
                                                                               userid={this.state.userid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/content/venuetypes/list"
                                                        component={props =>
                                                            <AsyncVenuetypeList {...props}
                                                                                userPermissions={this.state.userPermissions}
                                                                                showMessage={this.showMessage}
                                                                                userid={this.state.userid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/manager/content/venuetypes/:id/update"
                                                        component={props =>
                                                            <AsyncVenuetypeUpdator {...props}
                                                                                   showMessage={this.showMessage}
                                                                                   userid={this.state.userid}
                                                            />}/>

                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/manager/content/occasiontype/:id/update"
                                                        component={props =>
                                                            <AsyncOccasionsUpdator {...props}
                                                                                   showMessage={this.showMessage}
                                                                                   userid={this.state.userid}
                                                            />}/>

                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/content/subareas/list"
                                                        component={props =>
                                                            <AsyncSubAreaList {...props}
                                                                              userPermissions={this.state.userPermissions}
                                                                              cities={cities ? cities : []}
                                                                              showMessage={this.showMessage}
                                                                              userid={this.state.userid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/content/regions/list"
                                                        component={props =>
                                                            <AsyncRegionList {...props}
                                                                             userPermissions={this.state.userPermissions}
                                                                             cities={cities ? cities : []}
                                                                             showMessage={this.showMessage}
                                                                             userid={this.state.userid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/content/package-suggestions/list"
                                                        component={props =>
                                                            <AsyncPackageSuggestionList {...props}
                                                                                        userPermissions={this.state.userPermissions}
                                                                                        cities={cities ? cities : []}
                                                                                        showMessage={this.showMessage}
                                                                                        userid={this.state.userid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/content/newsletters/list"
                                                        component={AsyncNewsletterList}/>
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/content/jobs/list"
                                                        component={props =>
                                                            <AsyncJobsList {...props}
                                                                           userPermissions={this.state.userPermissions}
                                                                           cities={cities ? cities : []}
                                                                           showMessage={this.showMessage}
                                                                           userid={this.state.userid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/content/imagetags/list"
                                                        component={props =>
                                                            <AsyncImageTagsList {...props}
                                                                                userPermissions={this.state.userPermissions}
                                                                                cities={cities ? cities : []}
                                                                                showMessage={this.showMessage}
                                                                                userid={this.state.userid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/content/footerlinks/list"
                                                        component={props =>
                                                            <AsyncFooterLinkList {...props}
                                                                                 userPermissions={this.state.userPermissions}
                                                                                 cities={cities ? cities : []}
                                                                                 showMessage={this.showMessage}
                                                                                 userid={this.state.userid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/content/footerblogs/list"
                                                        component={props =>
                                                            <AsyncFooterBlogList {...props}
                                                                                 userPermissions={this.state.userPermissions}
                                                                                 cities={cities ? cities : []}
                                                                                 showMessage={this.showMessage}
                                                                                 userid={this.state.userid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/content/categories/list"
                                                        component={props =>
                                                            <AsyncCategoryList {...props}
                                                                               userPermissions={this.state.userPermissions}
                                                                               cities={cities ? cities : []}
                                                                               showMessage={this.showMessage}
                                                                               userid={this.state.userid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/content/listbizs/list"
                                                        component={props =>
                                                            <AsyncListbizList {...props}
                                                                              userPermissions={this.state.userPermissions}
                                                                              cities={cities ? cities : []}
                                                                              showMessage={this.showMessage}
                                                                              userid={this.state.userid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/content/colorlist/list"
                                                        component={props =>
                                                            <AsyncColorList {...props}
                                                                            userPermissions={this.state.userPermissions}
                                                                            cities={cities ? cities : []}
                                                                            showMessage={this.showMessage}
                                                                            userid={this.state.userid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/content/dropdowns/list"
                                                        component={props =>
                                                            <AsyncDropdownList {...props}
                                                                               userPermissions={this.state.userPermissions}
                                                                               cities={cities ? cities : []}
                                                                               showMessage={this.showMessage}
                                                                               userid={this.state.userid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/content/pagedatas/list"
                                                        component={props =>
                                                            <AsyncHomePageDataList {...props}
                                                                                   userPermissions={this.state.userPermissions}
                                                                                   cities={cities ? cities : []}
                                                                                   showMessage={this.showMessage}
                                                                                   userid={this.state.userid}
                                                            />}

                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/content/pagedatas/:id/update"
                                                        component={props =>
                                                            <AsyncHomePageDataUpdator {...props}
                                                                                      userPermissions={this.state.userPermissions}
                                                                                      cities={cities ? cities : []}
                                                                                      showMessage={this.showMessage}
                                                                                      userid={this.state.userid}
                                                            />}

                                                    />
                                                    {/*collections*/}
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/collections/list"
                                                        component={props =>
                                                            <AsyncCollectionList {...props}
                                                                                 showMessage={this.showMessage}
                                                                                 userid={this.state.userid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/collections/:id/update"
                                                        component={props =>
                                                            <AsyncCollectionUpdator {...props}
                                                                                    cities={cities ? cities : []}
                                                                                    showMessage={this.showMessage}
                                                                                    userid={this.state.userid}
                                                            />}
                                                    />
                                                    {/*enquiries*/}
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/enquiry/closed-won"
                                                        component={props =>
                                                            <AsyncEventWonList {...props}
                                                                               cities={cities ? cities : []}
                                                                               showMessage={this.showMessage}
                                                                               userid={this.state.userid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/content/subareas/list"
                                                        component={props =>
                                                            <AsyncSubAreaList {...props}
                                                                              userPermissions={this.state.userPermissions}
                                                                              cities={cities ? cities : []}
                                                                              showMessage={this.showMessage}
                                                                              userid={this.state.userid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/content/regions/list"
                                                        component={props =>
                                                            <AsyncRegionList {...props}
                                                                             userPermissions={this.state.userPermissions}
                                                                             cities={cities ? cities : []}
                                                                             showMessage={this.showMessage}
                                                                             userid={this.state.userid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/content/package-suggestions/list"
                                                        component={props =>
                                                            <AsyncPackageSuggestionList {...props}
                                                                                        userPermissions={this.state.userPermissions}
                                                                                        cities={cities ? cities : []}
                                                                                        showMessage={this.showMessage}
                                                                                        userid={this.state.userid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/content/newsletters/list"
                                                        component={AsyncNewsletterList}/>
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/content/jobs/list"
                                                        component={props =>
                                                            <AsyncJobsList {...props}
                                                                           userPermissions={this.state.userPermissions}
                                                                           cities={cities ? cities : []}
                                                                           showMessage={this.showMessage}
                                                                           userid={this.state.userid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/content/imagetags/list"
                                                        component={props =>
                                                            <AsyncImageTagsList {...props}
                                                                                userPermissions={this.state.userPermissions}
                                                                                cities={cities ? cities : []}
                                                                                showMessage={this.showMessage}
                                                                                userid={this.state.userid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/content/footerlinks/list"
                                                        component={props =>
                                                            <AsyncFooterLinkList {...props}
                                                                                 userPermissions={this.state.userPermissions}
                                                                                 cities={cities ? cities : []}
                                                                                 showMessage={this.showMessage}
                                                                                 userid={this.state.userid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/content/footerblogs/list"
                                                        component={props =>
                                                            <AsyncFooterBlogList {...props}
                                                                                 userPermissions={this.state.userPermissions}
                                                                                 cities={cities ? cities : []}
                                                                                 showMessage={this.showMessage}
                                                                                 userid={this.state.userid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/content/categories/list"
                                                        component={props =>
                                                            <AsyncCategoryList {...props}
                                                                               userPermissions={this.state.userPermissions}
                                                                               cities={cities ? cities : []}
                                                                               showMessage={this.showMessage}
                                                                               userid={this.state.userid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/content/listbizs/list"
                                                        component={props =>
                                                            <AsyncListbizList {...props}
                                                                              userPermissions={this.state.userPermissions}
                                                                              showMessage={this.showMessage}
                                                                              cities={cities ? cities : []}
                                                                              user={this.state.user}
                                                                              agentid={this.state.agentid}
                                                                              userid={this.state.userid}
                                                                              agent={this.state.agent}/>}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/content/colorlist/list"
                                                        component={props =>
                                                            <AsyncColorList {...props}
                                                                            userPermissions={this.state.userPermissions}
                                                                            cities={cities ? cities : []}
                                                                            showMessage={this.showMessage}
                                                                            userid={this.state.userid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/content/dropdowns/list"
                                                        component={props =>
                                                            <AsyncDropdownList {...props}
                                                                               userPermissions={this.state.userPermissions}
                                                                               cities={cities ? cities : []}
                                                                               showMessage={this.showMessage}
                                                                               userid={this.state.userid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/content/pagedatas/list"
                                                        component={AsyncHomePageDataUpdator}/>
                                                    {/*collections*/}
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/collections/list"
                                                        component={props =>
                                                            <AsyncCollectionList {...props}
                                                                                 showMessage={this.showMessage}
                                                                                 userid={this.state.userid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/collections/:id/update"
                                                        component={props =>
                                                            <AsyncCollectionUpdator {...props}
                                                                                    cities={cities ? cities : []}
                                                                                    showMessage={this.showMessage}
                                                                                    userid={this.state.userid}
                                                            />}
                                                    />
                                                    {/*enquiries*/}
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/enquiry/closed-won"
                                                        component={props =>
                                                            <AsyncEventWonList {...props}
                                                                               cities={cities ? cities : []}
                                                                               showMessage={this.showMessage}
                                                                               userid={this.state.userid}
                                                            />}

                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/enquiry/feedback"
                                                        component={AsyncFeedbackList}/>
                                                    {/*realevents*/}
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["realevent:create"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/realevents/create"
                                                        component={props =>
                                                            <AsyncRealEventCreator {...props}
                                                                                   cities={cities ? cities : []}
                                                                                   showMessage={this.showMessage}
                                                                                   userid={this.state.userid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["realevent:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/realevents/list"
                                                        component={AsyncRealEventsList}/>
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["realevent:update"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/manager/realevents/:id/update"
                                                        component={props =>
                                                            <AsyncRealEventUpdator {...props}
                                                                                   cities={cities ? cities : []}
                                                                                   showMessage={this.showMessage}
                                                                                   userid={this.state.userid}
                                                            />}
                                                    />
                                                    {/*analytics*/}
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/analytics/date"
                                                        component={AsyncDataDumper}/>
                                                    {/*reports*/}
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/reports/realtime"
                                                        component={props =>
                                                            <AsyncAdminReporting {...props}
                                                                                 cities={cities ? cities : []}
                                                                                 showMessage={this.showMessage}
                                                                                 userid={this.state.userid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/reports/monthly"
                                                        component={props =>
                                                            <AsyncAdminMonthlyReporting {...props}
                                                                                        cities={cities ? cities : []}
                                                                                        showMessage={this.showMessage}
                                                                                        userid={this.state.userid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/photographers/:city/:url/finance/update"
                                                        component={props =>
                                                            <AsyncPhotographerFinanceUpdator {...props}
                                                                                             showMessage={this.showMessage}
                                                                                             user={this.state.user}
                                                                                             agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/photographers/:city/:url/contacts/update"
                                                        component={props =>
                                                            <AsyncPhotographerContactUpdator {...props}
                                                                                             showMessage={this.showMessage}
                                                                                             user={this.state.user}
                                                                                             agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/photographers/:city/:url/update"
                                                        component={props =>
                                                            <AsyncPhotographerUpdator {...props}
                                                                                      showMessage={this.showMessage}
                                                                                      user={this.state.user}
                                                                                      agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/photographers/:city/:url/images/update"
                                                        component={props =>
                                                            <AsyncPhotographerImageUpdator {...props}
                                                                                           showMessage={this.showMessage}
                                                                                           user={this.state.user}
                                                                                           agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/photographer/list"
                                                        component={props =>
                                                            <AsyncPhotographerList {...props}
                                                                                   user={this.state.user}
                                                                                   agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/expressbook/expurl/list"
                                                        component={props =>
                                                            <AsyncExpurlList {...props}
                                                                             showMessage={this.showMessage}
                                                                             user={this.state.user}
                                                                             agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/services/tasktypes/list"
                                                        component={props =>
                                                            <AsyncTasktypeList {...props}
                                                                               userPermissions={this.state.userPermissions}
                                                                               showMessage={this.showMessage}
                                                                               user={this.state.user}
                                                                               agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/services/event-templates/list"
                                                        component={props =>
                                                            <AsyncEventTemplateList {...props}
                                                                                    userPermissions={this.state.userPermissions}
                                                                                    showMessage={this.showMessage}
                                                                                    user={this.state.user}
                                                                                    agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/services/venue-templates/list"
                                                        component={props =>
                                                            <AsyncVenueSummaryTemplate {...props}
                                                                                       userPermissions={this.state.userPermissions}
                                                                                       showMessage={this.showMessage}
                                                                                       user={this.state.user}
                                                                                       agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/services/venue-faq-template/list"
                                                        component={props =>
                                                            <AsyncVenueFaqTemplate {...props}
                                                                                   userPermissions={this.state.userPermissions}
                                                                                   showMessage={this.showMessage}
                                                                                   user={this.state.user}
                                                                                   cities={cities ? cities : []}
                                                                                   agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["marketing:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/marketing/smstoolkit/events"
                                                        component={props =>
                                                            <AsyncSmsEventList {...props}
                                                                               cities={cities ? cities : []}
                                                                               showMessage={this.showMessage}
                                                                               user={this.state.user}
                                                                               agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["marketing:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/marketing/venues/list"
                                                        component={props =>
                                                            <AsyncVenueList {...props}
                                                                            cities={cities ? cities : []}
                                                                            showMessage={this.showMessage}
                                                                            user={this.state.user}
                                                                            agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["marketing:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/marketing/emailtask/list"
                                                        component={props =>
                                                            <AsyncMarketingEmailTaskList {...props}
                                                                                         cities={cities ? cities : []}
                                                                                         showMessage={this.showMessage}
                                                                                         user={this.state.user}
                                                                                         agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["marketing:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/marketing/smstoolkit/venues"
                                                        component={props =>
                                                            <AsyncSmsVenueList {...props}
                                                                               cities={cities ? cities : []}
                                                                               showMessage={this.showMessage}
                                                                               user={this.state.user}
                                                                               agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["marketing:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/marketing/smstoolkit/users"
                                                        component={props =>
                                                            <AsyncSmsUserList {...props}
                                                                              cities={cities ? cities : []}
                                                                              showMessage={this.showMessage}
                                                                              user={this.state.user}
                                                                              agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["marketing:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/marketing/adlinks/list"
                                                        component={props =>
                                                            <AsyncAdLinkList {...props}
                                                                             userPermissions={this.state.userPermissions}

                                                                             cities={cities ? cities : []}
                                                                             showMessage={this.showMessage}
                                                                             user={this.state.user}
                                                                             agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["marketing:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/content/searchlinks/list"
                                                        component={props =>
                                                            <AsyncSearchLinkList {...props}
                                                                                 userPermissions={this.state.userPermissions}
                                                                                 cities={cities ? cities : []}
                                                                                 showMessage={this.showMessage}
                                                                                 userid={this.state.userid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["marketing:update"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/content/searchlinks/:id/update"
                                                        component={props =>
                                                            <AsyncSearchLinkUpdator {...props}
                                                                                    userPermissions={this.state.userPermissions}
                                                                                    cities={cities ? cities : []}
                                                                                    showMessage={this.showMessage}
                                                                                    userid={this.state.userid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/services/leadform/list"
                                                        component={props =>
                                                            <AsyncLeadFormList {...props}
                                                                               userPermissions={this.state.userPermissions}
                                                                               showMessage={this.showMessage}
                                                                               user={this.state.user}
                                                                               agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/services/alertsystem/list"
                                                        component={props =>
                                                            <AsyncAlertSystemList {...props}
                                                                                  userPermissions={this.state.userPermissions}
                                                                                  showMessage={this.showMessage}
                                                                                  user={this.state.user}
                                                                                  agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/services/leadform/list"
                                                        component={props =>
                                                            <AsyncLeadFormList {...props}
                                                                               showMessage={this.showMessage}
                                                                               user={this.state.user}
                                                                               agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/services/redirect/list"
                                                        component={props =>
                                                            <AsyncRedirectList {...props}
                                                                               userPermissions={this.state.userPermissions}
                                                                               showMessage={this.showMessage}
                                                                               user={this.state.user}
                                                                               agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/manager/services/leadform/:id/update"
                                                        component={props =>
                                                            <AsyncLeadFormUpdator {...props}
                                                                                  showMessage={this.showMessage}
                                                                                  user={this.state.user}
                                                                                  agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["dev:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/manager/scheduler/onetimeevent/list"
                                                        component={props =>
                                                            <AsyncOnetimeEventList {...props}
                                                                                   showMessage={this.showMessage}
                                                                                   user={this.state.user}
                                                                                   agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["dev:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/manager/scheduler/recurringevent/list"
                                                        component={props =>
                                                            <AsyncRecurringEventList {...props}
                                                                                     showMessage={this.showMessage}
                                                                                     user={this.state.user}
                                                                                     agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["dev:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/manager/scheduler/worker-jobs/list"
                                                        component={props =>
                                                            <AsyncWorkerJobsList {...props}
                                                                                 showMessage={this.showMessage}
                                                                                 user={this.state.user}
                                                                                 agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/manager/base/pagedata/list"
                                                        component={props =>
                                                            <AsyncPageDataList {...props}
                                                                               userPermissions={this.state.userPermissions}
                                                                               showMessage={this.showMessage}
                                                                               user={this.state.user}
                                                                               agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/manager/base/pagedata/:id/update"
                                                        component={props =>
                                                            <AsyncPageDataUpdator {...props}
                                                                                  showMessage={this.showMessage}
                                                                                  user={this.state.user}
                                                                                  agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["bd-role:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/manager/business-development/leads"
                                                        component={props =>
                                                            <AsyncBizLeadList {...props}
                                                                              showMessage={this.showMessage}
                                                                              user={this.state.user}
                                                                              agentid={this.state.agentid}
                                                                              cities={cities ? cities : []}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["bd-role:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/manager/business-development/lead-analytics"
                                                        component={props =>
                                                            <AsyncBDLeadAnalytics {...props}
                                                                                  showMessage={this.showMessage}
                                                                                  user={this.state.user}
                                                                                  agentid={this.state.agentid}
                                                                                  cities={this.state.cities}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["packagecosts:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/manager/business-development/package-cost"
                                                        component={props =>
                                                            <AsyncPackageCostData {...props}
                                                                                  userPermissions={this.state.userPermissions}
                                                                                  showMessage={this.showMessage}
                                                                                  user={this.state.user}
                                                                                  agentid={this.state.agentid}
                                                                                  cities={this.state.cities}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={[]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/manager/partner-app/users/list"
                                                        component={props =>
                                                            <AsyncPartnerUsersList {...props}
                                                                                   userPermissions={this.state.userPermissions}
                                                                                   showMessage={this.showMessage}
                                                                                   user={this.state.user}
                                                                                   agentid={this.state.agentid}
                                                                                   cities={this.state.cities}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["bd-role:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/manager/weblink/list"
                                                        component={props =>
                                                            <AsyncWebLinkList {...props}
                                                                              userPermissions={this.state.userPermissions}
                                                                              showMessage={this.showMessage}
                                                                              user={this.state.user}
                                                                              agentid={this.state.agentid}
                                                                              cities={this.state.cities}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["bd-role:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/manager/apilink/list"
                                                        component={props =>
                                                            <AsyncApiLinkList {...props}
                                                                              userPermissions={this.state.userPermissions}
                                                                              showMessage={this.showMessage}
                                                                              user={this.state.user}
                                                                              agentid={this.state.agentid}
                                                                              cities={this.state.cities}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["bd-role:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/manager/partner/users/list/:id/view-details"
                                                        component={props =>
                                                            <AsyncPartnerUserDetail {...props}
                                                                                    userPermissions={this.state.userPermissions}
                                                                                    showMessage={this.showMessage}
                                                                                    user={this.state.user}
                                                                                    agentid={this.state.agentid}
                                                                                    cities={this.state.cities}
                                                            />}
                                                    />
                                                    {/*partnerapp-services*/}
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/partner-app/services/email/templates"
                                                        component={props =>
                                                            <AsyncEmailTemplatesListForParntnerApp {...props}
                                                                                                   userPermissions={this.state.userPermissions}
                                                                                                   showMessage={this.showMessage}
                                                                                                   user={this.state.user}
                                                                                                   agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/services/email/:id/template-edit"
                                                        component={props =>
                                                            <AsyncEmailTemplatePreview {...props}
                                                                                       userPermissions={this.state.userPermissions}
                                                                                       showMessage={this.showMessage}
                                                                                       user={this.state.user}
                                                                                       agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/partner-app/services/sms/templates"
                                                        component={props =>
                                                            <AsyncSmsTemplatesListForParntnerApp {...props}
                                                                                                 userPermissions={this.state.userPermissions}
                                                                                                 showMessage={this.showMessage}
                                                                                                 user={this.state.user}
                                                                                                 agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={[]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/finance/venue-pending-payments"
                                                        component={props =>
                                                            <AsyncVenuePaymentList {...props}
                                                                                   cities={cities ? cities : []}
                                                                                   userPermissions={this.state.userPermissions}
                                                                                   showMessage={this.showMessage}
                                                                                   user={this.state.user}
                                                                                   agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/blog/blog-category/list"
                                                        component={props =>
                                                            <AsyncBlogCategoryList {...props}
                                                                                   userPermissions={this.state.userPermissions}
                                                                                   showMessage={this.showMessage}
                                                                                   user={this.state.user}
                                                                                   agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/blog/pinned-post/list"
                                                        component={props =>
                                                            <AsyncPinnedPostList {...props}
                                                                                 userPermissions={this.state.userPermissions}
                                                                                 showMessage={this.showMessage}
                                                                                 user={this.state.user}
                                                                                 agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/blog/featured-blog/list"
                                                        component={props =>
                                                            <AsyncFeaturedBlogList {...props}
                                                                                   userPermissions={this.state.userPermissions}
                                                                                   showMessage={this.showMessage}
                                                                                   user={this.state.user}
                                                                                   agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <Route
                                                        component={NotFound}/>
                                                </Switch>
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.isAuthenticated ?
                                        <footer role="contentinfo">
                                            <Footer/>
                                        </footer>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </Router>
                {this.state.isAuthenticated ?
                    <AppVersionModal
                        isAuthenticated={this.state.isAuthenticated}
                    /> :
                    null
                }
                <ToastContainer
                    newestOnTop={true}
                />
                <Notifications options={{zIndex: 10000}}/>
            </div>
        );
    }
}
function mapStateToProps(state, props) {
    return {
        agentId: getAgentId(state),
        agentName: getAgentName(state)
    }
}
export default connect(mapStateToProps)(App);
