import fetch from 'isomorphic-fetch';
import moment from 'moment';
const API_URL = process.env.REACT_APP_API_URL || `https://manager-api.venuemonk.com`;
const EVENT_API_URL = process.env.REACT_APP_VENUE_API_URL || `https://venue-api.venuemonk.com`
// const API_URL = `http://localhost:8080`;

export function callApiAndCache(endpoint, method = 'get', body) {
    let token = window.localStorage.getItem('token') ?  window.localStorage.getItem('token') : '';
    let headers = {};
    headers['content-type'] = 'application/json';
    if (token && token !=='') {
        headers.Authorization = `Bearer ${token}`;
    }
    let cacheResponse = window.localStorage.getItem(`api/${endpoint}`);
    if (cacheResponse) {
        let parsedResponse = JSON.parse(cacheResponse);
        if (parsedResponse.expires && moment(parsedResponse.expires).isAfter(moment())) {
            return Promise.resolve(parsedResponse.data);
        }
    }
    return fetch(`${API_URL}/api/${endpoint}`, {
        headers: headers,
        method,
        body: JSON.stringify(body),
    })
        .then(response => response.json().then(json => ({json, response})))
        .then(({json, response}) => {
            if (!response.ok) {
                return Promise.reject(json);
            }
            let cachedResponse = {
                data:  json,
                expires: moment().startOf('hour').add(1, 'hour').toISOString()
            }
            window.localStorage.setItem(`api/${endpoint}`, JSON.stringify(cachedResponse));
            return json;
        })
        .then(
            response => response,
            error => error
        );
}

export default function callApi(endpoint, method = 'get', body) {
    let token = window.localStorage.getItem('token') ?  window.localStorage.getItem('token') : '';
    let headers = {};
    headers['content-type'] = 'application/json';
    if (token && token !=='') {
        headers.Authorization = `Bearer ${token}`;
    }
    return fetch(`${API_URL}/api/${endpoint}`, {
        headers: headers,
        method,
        body: JSON.stringify(body),
    })
        .then(response => response.json().then(json => ({json, response})))
        .then(({json, response}) => {
            if (!response.ok) {
                return Promise.reject(json);
            }
            return json;
        })
        .then(
            response => response,
            error => error
        );
}


export function eventCallApi(endpoint, method = 'get', body) {
    let token = window.localStorage.getItem('token') ?  window.localStorage.getItem('token') : '';
    let headers = {};
    headers['content-type'] = 'application/json';
    if (token && token !=='') {
        headers.Authorization = `Bearer ${token}`;
    }
    return fetch(`${EVENT_API_URL}/${endpoint}`, {
        headers: headers,
        method,
        body: JSON.stringify(body),
    })
        .then(response => response.json().then(json => ({json, response})))
        .then(({json, response}) => {
            if (!response.ok) {
                return Promise.reject(json);
            }
            return json;
        })
        .then(
            response => response,
            error => error
        );
}

