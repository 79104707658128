import {
    AGENT_DETAILS, SET_AGENT_LIST
} from './AppAction';

const initialState = {
    agentName: '',
    agentId: '',
    totalAgentList: [],
    totalAgentMap: {}
};

const AppReducer = (state = initialState, action) => {
    let cities = [];

    switch (action.type) {

        case AGENT_DETAILS:
            return {
                ...state,
                agentName: action.agentName,
                agentId: action.agentId,
            };
        case SET_AGENT_LIST:
            let objectMap = {};
            if (action.agentList && action.agentList.length > 0) {
                action.agentList.forEach(agent => {
                    objectMap[agent._id] = agent;
                })
            }
            return {
                ...state,
                totalAgentList: action.agentList,
                totalAgentMap: objectMap
            };
            default:
                return state;
        }
    }

    export const getAgentName = (state) => state.app.agentName;
    export const getAgentId = (state) => state.app.agentId;
    export const getTotalAgentMap = (state) => state.user.totalAgentMap;

    export default AppReducer;