import callIdApi from "../../util/idApiCaller";

export const AGENT_DETAILS = "AGENT_DETAILS";
export const SET_AGENT_LIST = 'SET_AGENT_LIST';

export function setAgentDetails(name, id) {
    return (dispatch) => {
        dispatch({
            type: AGENT_DETAILS,
            agentName: name,
            agentId: id

        });
    };
}

export function setAgentList(agentList) {
    return (dispatch) => {
        dispatch({
            type: SET_AGENT_LIST,
            agentList: agentList
        });
    };
}



export function fetchAgentList () {
    return (dispatch) => {
       return  callIdApi(`v3/agents/agent-list`, 'POST', {}).then(res => {
            if (res.status === "Success") {
                dispatch(setAgentList(res?.data?.agents))
            }
        });
    }
}
