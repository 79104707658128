'use strict';

import _ from'lodash';
import callUrl from '../util/urlCaller';
import async from "async";
import callAppUrl from './urlAppCaller';
export function splitName(name) {
	var model = {
		fname: "",
		lname: "",
	};
	var fullName = name.split(" ", 3);
	if (fullName && fullName.length > 0) {
		fullName.forEach(function (item, i) {
			if (i === 0) {
				model.fname = sentenceCase(item);
			} else {
				model.lname += sentenceCase(item) + " ";
			}
		});
	}
	model.lname = model.lname.trim();
	return model;
}

export function sentenceCase(name) {
	return name.charAt(0).toUpperCase() + name.substr(1).toLowerCase();
}

export function groupAllData(data) {
    let temp = [];
    for (let a in data) {
        temp = temp.concat(data[a]);
    }
    return temp;
}

export function removeDuplicateElements(events) {
    let newEvents = [];
    for (let i = 0; i < events.length; i++) {
        let flag = 0;
        if (events[i]) {
            for (let j = i + 1; j < events.length; j++) {
                if (events[i] === events[j]) {
                    flag = 1;
                    break;
                }
            }
            if (flag === 0) {
                newEvents.push(events[i]);
            }
        }
    }
    return newEvents;
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function randomIntFromInterval(min,max) {
    return Math.floor(Math.random()*(max-min+1)+min);
}

export function getUserInfo(user) {
    return {
        _id: user._id,
        username: user.local.name,
        email: user.local.email,
        phone: user.phone,
        access_level: user.access_level,
        user_type: user.user_type,
        venue_list: user.venue_list
    };
}

export function isValidIndianMobile (phone) {
    let newPhone = _.toString(phone);
    return newPhone && newPhone.length === 10 && (newPhone[0] === '9' || newPhone[0] === '8' || newPhone[0] === '7' || newPhone[0] === '6');
}

// sorts in descending order
export function sortByKey(array, key) {
    return array.sort(function(a, b) {
        var x = a[key]; var y = b[key];
        return ((x < y) ? 1 : ((x > y) ? -1 : 0));
    });
}

export function hideMobileNumber (phone) {
    if (phone) {
        let strings = _.toString(phone).split('');
        if (strings && strings.length > 4) {
            strings = _.map(strings,  function (item, index) {
                if (index === 2 || index === 3 || index === 5 || index === 7 || index === 8) {
                    return 'X';
                } else {
                    return item;
                }
            });
            return strings.join('');
        } else {
            return '';
        }
    } else {
        return '';
    }
}

export function hideHalfChars (string , odd) {
    if (string && string !== '') {
        let strings = _.toString(string).split('');
        if (odd) {
            strings = _.map(strings,  function (item, index) {
                if (index%2 !== 0) {
                    return 'x';
                } else {
                    return item;
                }
            });
        } else {
            strings = _.map(strings,  function (item, index) {
                if (index%2 === 0) {
                    return 'x';
                } else {
                    return item;
                }
            });
        }
        return strings.join('');
    } else {
        return string;
    }
}

export function hideEmailAddress (email) {
    if (email) {
        let strings = _.toString(email).toLowerCase().split('.');
        let ext = strings.pop();
        strings = _.toString(strings.join('.')).split('@');
        if (strings && strings.length > 1) {
            let firstPart = hideHalfChars(strings[0], 1);
            let secondPart = hideHalfChars(strings[1], 0);
            return firstPart + "@" + secondPart + "." + ext;
        } else {
            return '';
        }
    } else {
        return '';
    }
}

export function bool(str) {
    if (str === void 0) return false;
    return str.toLowerCase() === 'true';
}

export function getCacheVenuePageUrl(cityslug,url) {
    return `${cityslug}/${url}`;
  }

export function getCacheVenueQAPageUrl(venue_id,url) {
return `venueqa-${venue_id}-${url}`;
}

export function syncElasticSearch(venue, next){
    let MANAGER_URL = process.env.REACT_APP_API_URL;
    let VENUE_URL = process.env.REACT_APP_VENUE_API_URL;
    async.parallel([
        (cb) => {
            callUrl(`${VENUE_URL}/v1/elastic-search/venues/${venue._id}/update`, 'get').then(res => {
                return cb();
            });
        }
    ], () => {
        return next(null, 'done');
    });
}

export function deleteCachelinks(venue, next){
    let API_URL = process.env.REACT_APP_CUSTOMER_API_URL;
    let WEB_URL = process.env.REACT_APP_CUSTOMER_APP_URL;
    let MANAGER_URL = process.env.REACT_APP_API_URL;
    async.parallel([
        (cb) => {
            callAppUrl(`${WEB_URL}/api/cache-invalidate`, 'post', {
                "pageType": "venue-overview",
                "url": {
                    url: `${venue.vm_id}`,
                    city_slug: `${venue.city_slug}`
                }
            }).then(res => {
                return cb();
            });
        },
        (cb) => {
            callUrl(`${API_URL}/venues/${venue.city_slug}/${venue.vm_id}/v2-pages/delete-cache`, 'get').then(res => {
                return cb();
            });
        },
        (cb) => {
            callUrl(`${MANAGER_URL}/api/v3/admin-dashboard/venue/${venue._id}/primary-assets/delete-cache`, 'get').then(res => {
                return cb();
            });
        },
        // (cb) => {
        //     callUrl(`${MANAGER_URL}/venues/venue/${venue._id}/primary-assets/delete-cache`, 'get').then(res => {
        //         return cb();
        //     });
        // }
    ], () => {
        return next(null, 'done');
    });
};

export function addcacheLinks(venue, next){
    let API_URL = process.env.REACT_APP_CUSTOMER_API_URL;
    let MANAGER_URL = process.env.REACT_APP_API_URL;
    async.parallel([
        (cb) => {
            callUrl(`${API_URL}/venues/${venue.city_slug}/${venue.vm_id}/v2-pages`, 'get').then(res => {
                return cb();
            });
        },
        (cb) => {
            callUrl(`${API_URL}/venues/${venue.city_slug}/${venue.vm_id}/v2-pages`, 'get').then(res => {
                return cb();
            });
        },
        (cb) => {
            callUrl(`${MANAGER_URL}/venues/venue/${venue._id}/primary-assets/add-cache`, 'get').then(res => {
                return cb();
            });
        }
    ], () => {
        return next(null, 'done');
    });
};
export function mediaType(filetype, file){
    let data = filetype && filetype.split('/');
    let filename = file.name.split('.');
    let ext = filename.pop();
    let result = '';
    if (data[0] === 'image') {
        result = 'image';
    } else if (data[0] === 'video') {
        result = 'video';
    } else if (data[0] === 'audio') {
        result = 'audio';
    }  else if (ext === 'pdf' || ext === 'doc' || ext === 'xls' || ext === 'xlsx' || ext === 'docx'){
        result = 'document'
    } else {
        result = 'invalid'
    }
    return result;
}
export function fileTypeUpload(filetype, file, upload_type) {
    let data = filetype && filetype.split('/');
    let filename = file.name.split('.');
    let ext = filename.pop();
    let result = '';
    let isValid = true;
    let message = '';
    if (data[0] === upload_type) {
        result = upload_type;
    } else if(upload_type === 'document'){
        if(ext === 'pdf' || ext === 'doc' || ext === 'xls' || ext === 'xlsx'|| ext === 'docx')
        {
            result = 'document'
        }else{
            result = 'invalid'
        }
    }else {
        result = 'invalid'
    }

    switch (result) {
        case 'image':
            if (parseInt(file.size) > parseInt(10) * 1024 * 1024) {
                isValid = false;
                message = `File size should be 10MB.`;
            }
            break;
        case 'video':
            if (parseInt(file.size) > parseInt(50) * 1024 * 1024) {
                isValid = false;
                message = `File size should be 50MB.`;
            }
            break;
        case 'audio':
            if (parseInt(file.size) > parseInt(10) * 1024 * 1024) {
                isValid = false;
                message = `File size should be 10MB.`;
            }
            break;
        case 'document':
            if (parseInt(file.size) > parseInt(20) * 1024 * 1024) {
                isValid = false;
                message = `File size should be 20MB.`;
            }
            break;
        default:
            isValid = false;
            message = 'Invalid filetype!'
    }
    return {
        isValid,
        message,
        result
    }
}

  export function isFileImage(filetype) {
    return filetype && filetype.split('/')[0] === 'image';
  }
  
  export function isFileVideo(filetype) {
    return filetype && filetype.split('/')[0] === 'video';
  }

  function rand(min, max) {
    const random = Math.random();
    return Math.floor(random * (max - min) + min);
  }
  
  export function createOTP(num) {
    const length = num || 4;
    const allowsChars = '0123456789';
    let password = '';
    for (let index = 0; index < length; ++index) {
      const charIndex = rand(0, allowsChars.length - 1);
      password += allowsChars[charIndex];
    }
    return password;
  }

 export function getAgentName (totalAgentMap, id){
    return totalAgentMap[id]?.name;
}

export default  {
	splitName: splitName,
	sentenceCase: sentenceCase,
    removeDuplicateElements:removeDuplicateElements,
    groupAllData: groupAllData,
    capitalizeFirstLetter: capitalizeFirstLetter,
    randomIntFromInterval: randomIntFromInterval,
    getUserInfo: getUserInfo,
    isValidIndianMobile: isValidIndianMobile,
    sortByKey: sortByKey,
    hideMobileNumber: hideMobileNumber,
    hideEmailAddress: hideEmailAddress,
    bool: bool,
    getCacheVenuePageUrl: getCacheVenuePageUrl,
    getCacheVenueQAPageUrl: getCacheVenueQAPageUrl,
    deleteCachelinks: deleteCachelinks,
    addcacheLinks: addcacheLinks,
    syncElasticSearch: syncElasticSearch,
    isFileVideo: isFileVideo,
    isFileImage:isFileImage,
    createOTP: createOTP,
    getAgentName:getAgentName
};
